import { apiCaller } from "../utils/generalUtils";
import {
  LOCATION_ADD_DATA,
  LOCATION_SET_LOADING,
  LOCATION_REMOVE_FROM_DATA,
  LOCATION_ADD_TO_DATA,
  LOCATION_UPDATE_DATA,
  LOCATION_ARCHIVE_TOGGLE,
} from "./types";
import {
  appStartLoading,
  appStopLoading,
  showSuccessMessage,
  showErrorMessageFromAPI,
} from "../actions";

export const locationFetch = (options) => {
  const force = options ? options.force : false;
  return (dispatch, getState) => {
    const { data, loaded } = getState().locations;
    if (loaded && data.length > 0 && force === false) {
      return;
    }
    try {
      dispatch(locationLoad(true));
      apiCaller
        .get("/locations")
        .then(({ data }) => {
          dispatch({
            type: LOCATION_ADD_DATA,
            payload: data.data,
          });
        })
        .catch((err) => {
          dispatch(showErrorMessageFromAPI({ err }));
        })
        .finally(() => {
          dispatch(locationLoad(false));
        });
    } catch (err) {
      dispatch(locationLoad(false));
      dispatch(showErrorMessageFromAPI({ err }));
    }
  };
};

export const locationDelete = ({ id, onSuccess }) => {
  return (dispatch) => {
    dispatch(appStartLoading());
    apiCaller
      .delete(`/locations/${id}`)
      .then(({ data }) => {
        dispatch({
          type: LOCATION_REMOVE_FROM_DATA,
          payload: id,
        });
        dispatch(showSuccessMessage("Location deleted"));
        onSuccess && onSuccess();
      })
      .catch((err) => {
        dispatch(showErrorMessageFromAPI({ err }));
      })
      .finally(() => {
        dispatch(appStopLoading());
      });
  };
};

export const locationArchive = ({ id, onSuccess }) => {
  return (dispatch) => {
    dispatch(appStartLoading());
    apiCaller
      .post(`/locations/${id}/archive`)
      .then(({ data }) => {
        dispatch({
          type: LOCATION_ARCHIVE_TOGGLE,
          payload: id,
        });
        dispatch(showSuccessMessage("Location archived"));
        onSuccess && onSuccess();
      })
      .catch((err) => {
        dispatch(showErrorMessageFromAPI({ err }));
      })
      .finally(() => {
        dispatch(appStopLoading());
      });
  };
};

export const locationUnarchive = ({ id, onSuccess }) => {
  return (dispatch) => {
    dispatch(appStartLoading());
    apiCaller
      .post(`/locations/${id}/unarchive`)
      .then(({ data }) => {
        dispatch({
          type: LOCATION_ARCHIVE_TOGGLE,
          payload: id,
        });
        dispatch(showSuccessMessage("Location Unarchived"));
        onSuccess && onSuccess();
      })
      .catch((err) => {
        dispatch(showErrorMessageFromAPI({ err }));
      })
      .finally(() => {
        dispatch(appStopLoading());
      });
  };
};

export const locationAdd = ({ data, onSuccess }) => {
  return (dispatch, getState) => {
    dispatch(appStartLoading());
    apiCaller
      .post("/locations", data)
      .then(({ data: { data } }) => {
        const r = getState().locations;
        if (r.data.length > 0) {
          dispatch({
            type: LOCATION_ADD_TO_DATA,
            payload: data,
          });
        } else {
          dispatch(locationFetch());
        }
        dispatch(showSuccessMessage("Location added"));
        onSuccess && onSuccess();
      })
      .catch((err) => {
        dispatch(showErrorMessageFromAPI({ err }));
      })
      .finally(() => {
        dispatch(appStopLoading());
      });
  };
};

export const locationUpdate = ({ data, onSuccess }) => {
  return (dispatch) => {
    dispatch(appStartLoading());
    apiCaller
      .put("/locations", data)
      .then(({ data: { data } }) => {
        dispatch({
          type: LOCATION_UPDATE_DATA,
          payload: data,
        });
        dispatch(showSuccessMessage("Location updated"));
        onSuccess && onSuccess();
      })
      .catch((err) => {
        dispatch(showErrorMessageFromAPI({ err }));
      })
      .finally(() => {
        dispatch(appStopLoading());
      });
  };
};

export const locationLoad = (loading) => {
  return {
    type: LOCATION_SET_LOADING,
    payload: loading,
  };
};
