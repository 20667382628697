import React, { useState } from "react";
import { Add } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { userActivityToggle, userDelete } from "../../actions";
import {
  ConfirmationDialog,
  DeleteDialog,
  ErrorBoundary,
  Header,
} from "../../components";
import { useFormManager } from "../../hooks";
import UserTable from "./table";
import { useDispatch } from "react-redux";
import UserForm from "./form";

const UserManagementPage = () => {
  const formManager = useFormManager();
  const dispatch = useDispatch();
  return (
    <ErrorBoundary>
      <Box>
        <Header heading={"User Management"}>
          <Button
            color="secondary"
            variant="contained"
            startIcon={<Add />}
            onClick={() => {
              formManager.show("add");
            }}
          >
            Create User
          </Button>
        </Header>
        <UserForm
          open={formManager.get("add") || Boolean(formManager.get("update"))}
          onClose={() => formManager.hide()}
          updateData={formManager.get("update")}
        />
        <DeleteDialog
          formManager={formManager}
          onDelete={(id, onSuccess) => dispatch(userDelete(id, onSuccess))}
          objectName="User"
          content={(name) =>
            `Are you sure you want to delete the user with the email: '${name}'?`
          }
        />
        <ConfirmationDialog
          title={"Confirm toggle of user activity"}
          open={Boolean(formManager.get("archive"))}
          onCancel={() => formManager.hide("archive")}
          onAccept={() => {
            const { id } = formManager.get("archive");
            const onSuccess = () => formManager.hide("archive");
            dispatch(userActivityToggle({ id, onSuccess }));
          }}
          content={`Confirm ${
            formManager.get("archive").status ? "Blocking" : "Activating"
          } of user with the email: ${formManager.get("archive").name}`}
          onAcceptText="YES"
        />
        <UserTable formManager={formManager} />
      </Box>
    </ErrorBoundary>
  );
};

export default UserManagementPage;
