import React from "react";
import { ButtonBase, Typography } from "@mui/material";

import { ChevronRight } from "@mui/icons-material";

const MenuButton = ({ text, icon, active }) => {
  return (
    <ButtonBase
      onClick={() => {}}
      active={active.toString() || undefined}
      sx={(theme) => {
        const darkMode = theme.palette.mode == "dark";
        return {
          "&:hover": {
            backgroundColor: !active && theme.palette.grey[200],
            color: theme.palette.grey[700],
          },
          boxShadow: active ? "0 2px 6px -1px rgb(0 0 0 / 20%)" : 0,
          color: active
            ? theme.palette.secondary.contrastText
            : darkMode
            ? "white"
            : theme.palette.grey[700],
          backgroundColor: active ? theme.palette.secondary.main : null,
          padding: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          borderRadius: 2,
          width: "100%",
        };
      }}
    >
      {icon}
      <Typography
        variant="button"
        sx={{ flex: 1, pl: 1 }}
        lineHeight={1}
        fontSize={12}
        textAlign={"left"}
      >
        {text}
      </Typography>
      <ChevronRight />
    </ButtonBase>
  );
};

export default MenuButton;
