import axios from "axios";

const urlBase =
  process.env.NODE_ENV === "development" ? "http://localhost:3003/api" : "/api";

export const apiCaller = axios.create({
  baseURL: urlBase,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

export const fileDownloadURL = (url) => {
  window.open(urlBase + url, "_blank").focus();
};

export const profileImage = `${urlBase}/profile/profilePic`;
