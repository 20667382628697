import { Alert, AlertTitle, Box, Button, Grid } from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { settingFetch, settingSMTPSave } from "../../actions/settingAction";
import { FormMaker } from "../../components";

export const SMTPTab = (props) => {
  const fields = [
    {
      id: "smtp_address",
      label: "SMTP Address",
    },
    {
      id: "smtp_from",
      label: "SMTP From Email",
    },
    {
      id: "smtp_username",
      label: "SMTP Username",
    },
    {
      id: "smtp_password",
      label: "SMTP Password",
    },
    {
      id: "smtp_port",
      label: "SMTP Port",
    },
  ];
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  useEffect(() => {
    setData(null);
    dispatch(
      settingFetch({
        group: "smtp",
        onSuccess: (data) => setData(data),
        onError: () => setData(false),
      })
    );
  }, []);

  if (data == null) {
    return (
      <Alert severity="info">
        <AlertTitle>Loading Settings</AlertTitle>Please wait the settings are
        loaded
      </Alert>
    );
  }
  if (data == false) {
    return (
      <Alert severity="error">
        <AlertTitle>Error loading Settings</AlertTitle>
        An unexpected error occurred while loading the settings
      </Alert>
    );
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        smtp_address: "",
        smtp_from: "",
        smtp_username: "",
        smtp_password: "",
        smtp_port: "",
        ...data,
      }}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(data, { setSubmitting, resetForm }) => {
        dispatch(settingSMTPSave(data));
      }}
    >
      {(props) => {
        return (
          <Box component="form" onSubmit={props.handleSubmit} sx={{}}>
            <FormMaker fields={fields} {...props} />
            <Button
              sx={{ mt: 2 }}
              variant="contained"
              type="submit"
              color="secondary"
            >
              save SMTP Settings
            </Button>
          </Box>
        );
      }}
    </Formik>
  );
};
