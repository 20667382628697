import { Collapse, Grid, Paper, Stack, Typography } from "@mui/material";
import { ErrorBoundary } from "../../components";

export const Practices = ({ hide }) => {
  return (
    <ErrorBoundary>
      <Collapse in={!hide}>
        <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
          <Grid container spacing={2}>
            {/* <Grid xs={4} item>
            <iframe
              overflow="auto"
              width="100%"
              height="250px"
              src="https://www.youtube.com/embed/1c-3FQap7iw"
              title="GYM BUILDER METHOD™ Starter"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="allowfullscreen"
              mozallowfullscreen="mozallowfullscreen"
              msallowfullscreen="msallowfullscreen"
              oallowfullscreen="oallowfullscreen"
              webkitallowfullscreen="webkitallowfullscreen"
            ></iframe>
          </Grid> */}
            <Grid xs={12} item>
              <Stack spacing={2}>
                <Typography variant="h5">Best Practices</Typography>
                <Stack spacing={1}>
                  <Typography>
                    1. THE TREND IS YOUR FRIEND; REVIEW THE DATA MONTHLY TO ID
                    PATTERNS
                  </Typography>
                  <Typography>
                    2. MOMENTUM IN ACTION; ID WHAT TO STOP DOING, DO MORE OF AND
                    WHAT TO START NEW
                  </Typography>
                  <Typography>
                    3. SEEK ANSWERS; FIND PEOPLE WHO HAVE SOLVED YOUR PROBLEM OR
                    ARE DOING IT BETTER
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Collapse>
    </ErrorBoundary>
  );
};
