import React from "react";
import { Drawer, Stack, Toolbar, Typography, CssBaseline } from "@mui/material";
import { Box } from "@mui/system";
import coverImage from "../../images/cover.png";
import { useLocation, Link } from "react-router-dom";
import {
  Home as HomeIcon,
  Add as AddIcon,
  Preview as ViewIcon,
  Leaderboard as LeaderboardIcon,
  School as LearnIcon,
  Support as SupportIcon,
  AccountCircle as ProfileIcon,
  LocationOn as LocationsIcon,
  Person as UserIcon,
  Settings as SettingIcon,
  Feedback as FeedbackIcon,
} from "@mui/icons-material";
import MenuButton from "./MenuButton";
import { useSelector } from "react-redux";

const MENU = [
  {
    text: "Home",
    icon: <HomeIcon />,
    link: "/",
  },
  {
    text: "My Profile",
    icon: <ProfileIcon />,
    link: "/profile",
  },
  {
    text: "My Locations",
    icon: <LocationsIcon />,
    link: "/locations",
  },
  {
    text: "Add Data",
    icon: <AddIcon />,
    link: "/data/add",
  },
  {
    text: "View Data",
    icon: <ViewIcon />,
    link: "/data",
  },
  {
    text: "Leaderboard",
    icon: <LeaderboardIcon />,
    link: "/leaderboard",
  },
  {
    text: "Learn from the best",
    icon: <LearnIcon />,
    link: "/education",
  },
  {
    text: "Account Support",
    icon: <SupportIcon />,
    link: "/support",
  },
  {
    text: "Feedback",
    icon: <FeedbackIcon />,
    link: "/feedback",
  },
  {
    text: "user Management",
    icon: <UserIcon />,
    link: "/users",
    isAdminOnly: true,
  },
  {
    text: "Settings",
    icon: <SettingIcon />,
    link: "/settings",
    isAdminOnly: true,
  },
];

const drawerWidth = 270;

const Menu = ({ children, path, hideMenu, ...props }) => {
  const { showSideMenu } = useSelector(({ app }) => app);
  if (hideMenu) return children;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer
        open={showSideMenu}
        variant={showSideMenu ? "permanent" : "fixed"}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <Box>
          <PersonalBox />
          <MenuList />
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: `calc(100% - ${drawerWidth}px)` }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};
export default Menu;

const PersonalBox = () => {
  const profileData = useSelector(({ profile }) => profile.data);
  const userName = profileData && profileData.userName;
  const businessName = (profileData && profileData.businessName) || "-";
  return (
    <Box
      sx={{
        minHeight: 170,
        backgroundImage: `url(${coverImage})`,
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        "& > *": {
          padding: 2,
          paddingTop: 1,
          paddingBottom: 1,
        },
        "&:before": {
          content: `""`,
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "black",
          opacity: 0.2,
        },
      }}
    >
      <Box
        flex={1}
        display={"flex"}
        alignItems={"center"}
        sx={{ "&  img": { borderRadius: "50%" } }}
      ></Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        color="white"
        position={"relative"}
        sx={{
          "& > *": { zIndex: 1 },
          "&:before": {
            content: `""`,
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "black",
            opacity: 0.7,
          },
        }}
      >
        <Typography variant="body" sx={{ textTransform: "capitalize" }}>
          {userName}
        </Typography>
        <Typography variant="caption" sx={{ textTransform: "capitalize" }}>
          {businessName}
        </Typography>
      </Box>
    </Box>
  );
};
const MenuList = () => {
  const { isAdmin } = useSelector(({ profile: { data } }) => data);
  const location = useLocation();
  return (
    <Stack spacing={2} padding={2}>
      {MENU.map((props) => {
        if (props.isAdminOnly && !isAdmin) {
          return null;
        }
        return (
          <Link
            key={`menulink-${props.link}`}
            to={props.link}
            style={{ textDecoration: "none" }}
          >
            <MenuButton active={props.link === location.pathname} {...props} />
          </Link>
        );
      })}
    </Stack>
  );
};
