import { apiCaller } from "../utils/generalUtils";
import { showErrorMessageFromAPI } from "../actions";
import { showSuccessMessage } from "./appAction";

export const settingFetch = ({ group, onSuccess, onError }) => {
  return (dispatch) => {
    try {
      apiCaller
        .get(`/settings?group=${group}`)
        .then(({ data }) => {
          onSuccess(data.data);
        })
        .catch((err) => {
          onError();
          dispatch(showErrorMessageFromAPI({ err }));
        })
        .finally(() => {});
    } catch (err) {
      dispatch(showErrorMessageFromAPI({ err }));
    }
  };
};

export const settingSMTPSave = (data) => {
  return (dispatch) => {
    try {
      apiCaller
        .post(`/settings/smtp`, data)
        .then(({ data }) => {
          dispatch(showSuccessMessage("SMTP Settings saved"));
        })
        .catch((err) => {
          dispatch(showErrorMessageFromAPI({ err }));
        })
        .finally(() => {});
    } catch (err) {
      dispatch(showErrorMessageFromAPI({ err }));
    }
  };
};

export const settingStandardSave = (data) => {
  return (dispatch) => {
    try {
      apiCaller
        .post(`/settings/standard`, data)
        .then(({ data }) => {
          dispatch(showSuccessMessage("Standard Settings saved"));
        })
        .catch((err) => {
          dispatch(showErrorMessageFromAPI({ err }));
        })
        .finally(() => {});
    } catch (err) {
      dispatch(showErrorMessageFromAPI({ err }));
    }
  };
};
