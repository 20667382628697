import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { LoginCheckWall, ScreenLoader } from "..";
import { Notification } from "../Notification";
import NavBar from "./NavBar";
import Menu from "./Menu";
import { useSelector } from "react-redux";

let Layout = ({ children }) => {
  let navigate = useNavigate();

  const { logged, sessionChecked, loading, profile } = useSelector(
    ({ profile, app: { logged, sessionChecked, loading } }) => ({
      sessionChecked,
      logged,
      loading,
      profile,
    })
  );

  const location = useLocation();

  const hideNav = ["/login/", "/login"].includes(location.pathname);
  const hideMenu = ["/login/", "/login", "/setup/", "/setup"].includes(
    location.pathname
  );

  if (!sessionChecked) return <LoginCheckWall open={true} />;

  const {
    data: { hasCompletedSetup },
  } = profile;

  if (
    logged &&
    !hasCompletedSetup &&
    !["/setup/", "/setup"].includes(location.pathname)
  ) {
    navigate("/setup");
  }

  if (
    logged &&
    hasCompletedSetup &&
    ["/setup/", "/setup"].includes(location.pathname)
  ) {
    navigate("/");
  }

  return (
    <>
      <Notification />
      <ScreenLoader open={loading} />
      {!hideNav && <NavBar />}
      <Menu hideMenu={hideMenu}>{children}</Menu>
    </>
  );
};

export { Layout };
