import React, { useEffect } from "react";
import {
  TextField,
  Box,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  ListItemText,
  Checkbox,
  Autocomplete,
  Chip,
  FormHelperText,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import { Info } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { locationFetch, userTypeFetch } from "../actions";
import { quarterOptions, weekOptions, monthOptions } from "../utils/timeUtils";

const camelToTitle = (camelCase) =>
  camelCase
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();

export const FormikTextField = ({
  label,
  id,
  value,
  values = {},
  errors,
  handleChange,
  handleBlur,
  noLabel,
  toolTip,
  ...props
}) => {
  const _value = value || (values[id] !== undefined ? values[id] : "");
  const newProps = { ...props };
  delete newProps.noDecimal;
  delete newProps.setFieldValue;
  const textField = (
    <TextField
      autoComplete="off"
      name={id}
      fullWidth
      label={!noLabel && (label || camelToTitle(id))}
      value={_value}
      onChange={handleChange}
      onBlur={handleBlur}
      size="small"
      helperText={errors && errors[id]}
      error={errors && Boolean(errors[id])}
      {...newProps}
    />
  );
  if (!toolTip) return textField;
  return (
    <Box sx={{ position: "relative" }}>
      <Box>{textField}</Box>
      <Box
        sx={{
          position: "absolute",
          top: "8px",
          right: "10px",
          transform: "translate(0%,00%)",
        }}
      >
        <Tooltip
          arrow
          disableFocusListener
          title={toolTip}
          placement="bottom-end"
        >
          <Info sx={({ palette: { grey } }) => ({ color: grey[600] })} />
        </Tooltip>
      </Box>
    </Box>
  );
};

export const FormikSelect = ({
  label,
  id,
  value,
  values = {},
  errors,
  handleChange,
  handleBlur,
  noLabel,
  toolTip,
  options,
  setFieldValue,
  multiple = false,
  showIndex,
  ...props
}) => {
  const _value = value || (values[id] !== undefined ? values[id] : "");
  const select = (
    <FormControl fullWidth error={errors && Boolean(errors[id])}>
      <InputLabel id={id} size={props.size || "small"}>
        {!noLabel && (label || camelToTitle(id))}
      </InputLabel>
      <Select
        multiple={multiple}
        fullWidth
        label={!noLabel && (label || camelToTitle(id))}
        value={_value}
        onChange={(e) => setFieldValue(id, e.target.value)}
        onBlur={handleBlur}
        size="small"
        error={errors && Boolean(errors[id])}
        renderValue={(selected) => {
          if (!multiple) {
            const selectedValue = options.find((x) => x.id == selected);
            return selectedValue ? selectedValue.label : undefined;
          }
          return (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((id, index) => (
                <Chip
                  color="primary"
                  key={id}
                  label={
                    (showIndex ? `#${index + 1} - ` : "") +
                    options.find((x) => x.id == id).label
                  }
                />
              ))}
            </Box>
          );
        }}
        {...props}
      >
        {options.map(({ id, label }) => {
          if (multiple) {
            let rank = "";
            try {
              if (showIndex) {
                const index = _value.findIndex((x) => x == id);
                if (index + 1 >= 1) {
                  rank = `#${index + 1} - `;
                }
              }
            } catch (err) {}
            return (
              <MenuItem key={id} value={id}>
                <Checkbox checked={_value.indexOf(id) > -1} />
                <ListItemText primary={rank + id} />
              </MenuItem>
            );
          }
          return (
            <MenuItem key={id} value={id}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
      {errors && errors[id] && (
        <FormHelperText>{errors && errors[id]}</FormHelperText>
      )}
    </FormControl>
  );
  if (!toolTip) return select;
  return (
    <Box sx={{ position: "relative" }}>
      <Box>{select}</Box>
      <Box
        sx={{
          position: "absolute",
          top: "8px",
          right: "10px",
        }}
      >
        <Tooltip
          arrow
          disableFocusListener
          title={toolTip}
          placement="bottom-end"
        >
          <Info sx={({ palette: { grey } }) => ({ color: grey[600] })} />
        </Tooltip>
      </Box>
    </Box>
  );
};

export const FormikAutocomplete = ({
  label,
  id,
  value,
  values = {},
  errors,
  handleChange,
  handleBlur,
  noLabel,
  toolTip,
  options,
  setFieldValue,
  size,
  disableClearable,
  ...props
}) => {
  const _value = value || (values[id] !== undefined ? values[id] : null);
  const select = (
    <Autocomplete
      disablePortal
      id={id}
      value={_value}
      onChange={(e, value) => setFieldValue(id, value ? value.id : null)}
      options={options}
      size={size || "small"}
      fullWidth
      disableClearable={disableClearable}
      renderInput={(params) => (
        <TextField
          fullWidth
          size={size || "small"}
          error={errors && Boolean(errors[id])}
          helperText={errors && errors[id]}
          {...params}
          label={!noLabel && (label || camelToTitle(id))}
        />
      )}
    />
  );
  if (!toolTip) return select;
  return (
    <Box sx={{ position: "relative" }}>
      <Box>{select}</Box>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          right: "10px",
          transform: "translate(0%,-40%)",
        }}
      >
        <Tooltip disableFocusListener title={toolTip} placement="bottom-end">
          <Info sx={({ palette: { grey } }) => ({ color: grey[600] })} />
        </Tooltip>
      </Box>
    </Box>
  );
};

export const LocationSelector = (props) => {
  const dispatch = useDispatch();
  let locations = useSelector(({ locations }) => locations.data);
  if (props.hideArchived) {
    locations = locations.filter(({ archived }) => !archived);
  }
  const locationOptions = locations.map(({ id, tag }) => ({ id, label: tag }));

  useEffect(() => {
    dispatch(locationFetch());
  }, []);
  return (
    <FormikSelect
      id="LocationId"
      label="Location"
      options={locationOptions}
      {...props}
    />
  );
};

export const UserTypeSelector = (props) => {
  const dispatch = useDispatch();
  const userTypes = useSelector(({ userTypes }) => userTypes.data);
  const userTypeOptions = userTypes.map(({ id, userType }) => ({
    id,
    label: userType,
  }));
  useEffect(() => {
    dispatch(userTypeFetch());
  }, []);
  return (
    <FormikSelect
      id="UserTypeId"
      label="User Type"
      options={userTypeOptions}
      {...props}
    />
  );
};

export const YearSelector = (props) => {
  const currentYear = new Date().getFullYear();
  const leastYear = props.startYear || currentYear - 100;
  let options = Array.apply(null, { length: currentYear + 1 })
    .map(Number.call, Number)
    .slice(leastYear);
  options = options.reverse();
  options = options.map((year) => ({ id: String(year), label: String(year) }));
  return (
    <FormikAutocomplete
      id="year"
      options={options}
      {...props}
      setFieldValue={(id, value) => {
        props.setFieldValue("month", "");
        props.setFieldValue("quarter", "");
        props.setFieldValue(id, value);
      }}
      disableClearable={true}
    />
  );
};

export const WeekSelector = (props) => {
  const { year } = props;
  let options = [];
  if (year) {
    options = weekOptions(year);
  }
  return (
    <FormikSelect id="week" disabled={!year} options={options} {...props} />
  );
};

export const MonthSelector = (props) => {
  const { year } = props;
  let options = [];
  if (year) {
    options = monthOptions(year);
  }
  return (
    <FormikSelect id="month" disabled={!year} options={options} {...props} />
  );
};

export const QuarterSelector = (props) => {
  const { year } = props;
  let options = [];
  if (year) {
    options = quarterOptions(year);
  }
  return (
    <FormikSelect id="quarter" disabled={!year} options={options} {...props} />
  );
};

export const MonthYearSelector = ({
  minDate,
  maxDate,
  values,
  value,
  id,
  noLabel,
  label,
  setFieldValue,
  disabled,
  errors,
}) => {
  let _value = null;
  try {
    _value = value || (values[id] !== undefined ? values[id] : null);
  } catch {}
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        disabled={disabled}
        fullWidth
        size="small"
        error={false}
        views={["year", "month"]}
        label={!noLabel && (label || camelToTitle(id))}
        minDate={minDate || null}
        maxDate={maxDate || null}
        value={_value}
        onChange={(newValue) => {
          setFieldValue(id, newValue);
        }}
        renderInput={(params) => {
          return (
            <TextField
              fullWidth
              size="small"
              {...params}
              error={errors[id]}
              helperText={errors && errors[id]}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

export const FormMaker = ({
  values,
  errors,
  setFieldValue,
  handleChange,
  handleBlur,
  fields,
  direction = "column",
}) => {
  const fieldProps = {
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
  };
  return (
    <Stack direction={direction} spacing={2} sx={{ pt: 2 }}>
      {fields.map((props, index, showIndex) => {
        if (props.type == "component") {
          const { component, display, disabled } = props;
          if (display && !display(values)) return null;
          return (
            <Box key={index} sx={{ width: "100%" }}>
              {component(fieldProps, values)}
            </Box>
          );
        }
        if (props.type == "select") {
          return <FormikSelect key={index} {...props} {...fieldProps} />;
        }
        if (props.type == "multiselect") {
          return (
            <FormikSelect
              showIndex={showIndex}
              key={index}
              multiple
              {...props}
              {...fieldProps}
            />
          );
        }
        return (
          <FormikTextField
            key={index}
            {...props}
            {...fieldProps}
            multiline={props.type == "textarea"}
            onKeyPress={(e) => {
              if (e.code == "Enter") return;
              if (!props.noDecimal) return;
              const re = /^\d+/;
              if (!re.test(e.key)) {
                return e.preventDefault();
              }
            }}
          />
        );
      })}
    </Stack>
  );
};
