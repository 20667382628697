import { apiCaller } from "../utils/generalUtils";
import {
  APP_SET_LOGIN,
  APP_HIDE_NOTIFICATION,
  APP_SHOW_NOTIFICATION,
  APP_START_LOADING,
  APP_STOP_LOADING,
  APP_TOGGLE_SIDEMENU,
} from "./types";

export const appLogin = ({ data, setErrors }) => {
  return async (dispatch) => {
    try {
      dispatch(appStartLoading());
      apiCaller
        .post("/auth/login", data)
        .then(({ data }) => {
          dispatch({
            type: APP_SET_LOGIN,
            payload: data.data,
          });
          // navigate("/");
        })
        .catch((err) => {
          dispatch({
            type: APP_SET_LOGIN,
            payload: false,
          });
          dispatch(showErrorMessageFromAPI({ err }));
        })
        .finally(() => {
          dispatch(appStopLoading());
        });
    } catch (err) {
      dispatch(appStopLoading());
      dispatch(showErrorMessageFromAPI({ err }));
    }
  };
};

export const appLogout = () => {
  return async (dispatch) => {
    try {
      apiCaller
        .post("/auth/logout")
        .then(() => {
          // navigate("/login");
          if (typeof window !== undefined) window.location.reload();
        })
        .catch((err) => {
          dispatch(showErrorMessageFromAPI({ err }));
        });
    } catch (err) {
      dispatch(showErrorMessageFromAPI({ err }));
    }
  };
};

export const appCheckLogin = () => {
  return async (dispatch) => {
    try {
      apiCaller
        .get("/auth/check")
        .then(({ data }) => {
          dispatch({
            type: APP_SET_LOGIN,
            payload: data.data,
          });
        })
        .catch((err) => {
          dispatch({
            type: APP_SET_LOGIN,
            payload: false,
          });
          // navigate("/login");
        });
    } catch (err) {
      // navigate("/login");
    }
  };
};

export const appHideNotification = () => {
  return {
    type: APP_HIDE_NOTIFICATION,
  };
};

export const showNotification = (type, text) => {
  return {
    type: APP_SHOW_NOTIFICATION,
    payload: { type, text },
  };
};

export const showSuccessMessage = (text) => {
  return (dispatch) => {
    dispatch(showNotification("success", text));
  };
};

export const showErrorMessage = (text) => {
  return (dispatch) => {
    dispatch(showNotification("error", text));
  };
};

export const showErrorMessageFromAPI = ({ err, text }) => {
  return (dispatch) => {
    let errorMessage = "";
    if (text) {
      errorMessage = text;
    } else {
      try {
        if (err.response.data.message) {
          errorMessage = err.response.data.message;
        } else {
          errorMessage = "Something went wrong!";
        }
      } catch (err) {
        errorMessage = "Something went wrong!";
      }
    }
    dispatch(showErrorMessage(errorMessage));
  };
};

export const appStartLoading = () => {
  return {
    type: APP_START_LOADING,
  };
};

export const appStopLoading = () => {
  return {
    type: APP_STOP_LOADING,
  };
};

export const toggleSideMenu = () => {
  return {
    type: APP_TOGGLE_SIDEMENU,
  };
};
