import { Box, Paper, Typography, Button, Stack } from "@mui/material";
import * as yup from "yup";
import {
  FormMaker,
  MonthYearSelector,
  YearSelector,
  MonthSelector,
  QuarterSelector,
} from ".";
import { Formik } from "formik";
import moment from "moment";

const schema = {
  dataViewMode: yup
    .string()
    .oneOf(["operations", "leverage", "finances", "all"])
    .required("Data View Mode is required"),
  period: yup
    .string()
    .oneOf(["month", "monthRange", "quarter"])
    .required("Period is required"),
  year: yup
    .string()
    .required("Year is required")
    .when("period", (period) => {
      if (period == "monthRange") {
        return yup.string();
      }
    }),
  quarter: yup
    .string()
    .typeError("Quarter is missing")
    .nullable()
    .when("period", (period) => {
      if (period == "quarter") {
        return yup
          .string()
          .typeError("Quarter is missing")
          .required("Quarter is required");
      }
    }),
  month: yup
    .string()
    .typeError("Month is missing")
    .nullable()
    .when("period", (period) => {
      if (period == "month") {
        return yup
          .string()
          .typeError("Month is missing")
          .required("Month is required");
      }
    }),
  startingMonth: yup
    .string()
    .nullable()
    .when("period", (period) => {
      if (period == "monthRange") {
        return yup.string().required("Starting month is required");
      }
    }),
  endingMonth: yup
    .string()
    .nullable()
    .when("period", (period) => {
      if (period == "monthRange") {
        return yup.string().required("Ending month is required");
      }
    }),
};

const dataFilterSchema = yup.object(schema);
const leaderboardDataFilterSchema = yup.object({
  ...schema,
  profileWise: yup.boolean().required(),
});

export const DataFilter = ({
  filter,
  setFilter,
  loadData,
  toggleTableMode,
  tableMode,
  leaderboard = false,
}) => {
  const fields = [
    {
      id: "dataViewMode",
      type: "select",
      options: [
        { id: "all", label: "All" },
        { id: "finances", label: "Finances" },
        { id: "operations", label: "Operations" },
        { id: "leverage", label: "Leverage" },
      ],
    },
    {
      id: "period",
      type: "select",
      options: [
        { id: "monthRange", label: "Month Range" },
        { id: "month", label: "Month" },
        { id: "quarter", label: "Quarter" },
      ],
    },
    {
      type: "component",
      component: (props, values) => (
        <YearSelector id="year" {...props} startYear={2019} />
      ),
      display: (values) =>
        values.period == "month" || values.period == "quarter",
    },
    {
      type: "component",
      component: (props, values) => (
        <MonthSelector id="month" year={values.year} {...props} />
      ),
      display: (values) => values.period == "month",
    },
    {
      type: "component",
      component: (props, values) => (
        <QuarterSelector id="quarter" year={values.year} {...props} />
      ),
      display: (values) => values.period == "quarter",
    },
    {
      type: "component",
      component: (props, values) => (
        <MonthYearSelector id="startingMonth" maxDate={new Date()} {...props} />
      ),
      display: (values) => values.period == "monthRange",
    },
    {
      type: "component",
      component: (props, values) => (
        <MonthYearSelector
          id="endingMonth"
          maxDate={new Date()}
          minDate={new Date(values.startingMonth)}
          disabled={!values.startingMonth}
          {...props}
        />
      ),
      display: (values) => values.period == "monthRange",
    },
  ];

  return (
    <Formik
      initialValues={{
        dataViewMode: "",
        period: "",
        year: "",
        month: "",
        quarter: "",
        startingMonth: "",
        endingMonth: "",
        ...filter,
      }}
      enableReinitialize={true}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={
        leaderboard ? leaderboardDataFilterSchema : dataFilterSchema
      }
      onSubmit={async (data) => {
        setFilter(data);
        const newData = { ...data };
        if (newData.period === "monthRange") {
          newData.endingMonth = moment(newData.endingMonth).format("yyyy-MM");
          newData.startingMonth = moment(newData.startingMonth).format(
            "yyyy-MM"
          );
        }
        loadData(newData);
      }}
    >
      {(props) => {
        return (
          <Paper
            variant="outlined"
            onSubmit={props.handleSubmit}
            component="form"
            sx={{
              "& > div:not(:last-child)": { pb: 2 },
              mb: 2,
              p: 2,
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              Filter Data
            </Typography>
            <FormMaker {...props} direction="row" fields={fields} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>{selectedFilter(filter)}</Box>
              <Stack direction="row" spacing={2}>
                {filter.dataViewMode == "finances" && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={toggleTableMode}
                  >
                    Switch To {tableMode ? "Chart" : "Table"}
                  </Button>
                )}
                {leaderboard ? (
                  <>
                    <Button
                      variant="contained"
                      type="submit"
                      onClick={() => {
                        props.setFieldValue("profileWise", false);
                      }}
                    >
                      View Locations
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      onClick={() => {
                        props.setFieldValue("profileWise", true);
                      }}
                    >
                      View Profiles
                    </Button>
                  </>
                ) : (
                  <Button variant="contained" type="submit">
                    View Data
                  </Button>
                )}
              </Stack>
            </Box>
          </Paper>
        );
      }}
    </Formik>
  );
};

const selectedFilter = (filter) => {
  console.log(filter);
  const {
    profileWise,
    period,
    year,
    month,
    quarter,
    startingMonth,
    endingMonth,
  } = filter;
  const filterCheck = { ...filter };
  delete filterCheck.period;
  if (Object.keys(filterCheck).length == 0) return "No Filter Selected";
  let text = "";
  if (period == "month") {
    text = `Filtering by Month [${moment()
      .month(month - 1)
      .format("MMMM")} ${year}]`;
  } else if (period == "quarter") {
    text = `Filtering by Quarter [Q${quarter} ${year}]`;
  } else if (period == "monthRange") {
    text = `Filtering by Month Range [${moment(startingMonth).format(
      "MMMM YYYY"
    )} - ${moment(endingMonth).format("MMMM YYYY")}]`;
  }
  if (profileWise) {
    text += " for Profiles";
  }
  return <Typography variant="body2">{text}</Typography>;
};
