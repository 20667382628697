import React from "react";
import { Backdrop, CircularProgress, Typography } from "@mui/material";

export const ScreenLoader = ({ open }) => {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        flexDirection: "column",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={Boolean(open)}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export const LoginCheckWall = ({ open }) => {
  return (
    <Backdrop
      sx={({ palette: { primary } }) => ({
        backgroundColor: primary.main,
        color: "#fff",
        flexDirection: "column",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      })}
      open={true}
    >
      <CircularProgress color="inherit" />
      <Typography sx={{ pt: 2 }}>LOADING</Typography>
    </Backdrop>
  );
};
