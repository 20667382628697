import { Alert, AlertTitle, Box, Button, Grid } from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { settingFetch, settingStandardSave } from "../../actions/settingAction";
import { FormMaker } from "../../components";

export const StandardTab = (props) => {
  const fields = [
    {
      id: "standard_retain",
      label: "Retain Recommended Standard",
    },
    {
      id: "standard_convert_consultation",
      label: "Convert Recommended Standard [Consultation]",
    },
    {
      id: "standard_convert_trial",
      label: "Convert Recommended Standard [Trial]",
    },
    {
      id: "standard_attract_consultation",
      label: "Attract Recommended Standard [Consultation]",
    },
    {
      id: "standard_attract_trial",
      label: "Attract Recommended Standard [Trial]",
    },
    {
      id: "standard_leverage",
      label: "Leverage Recommended Standard",
    },
    {
      id: "standard_ownerscompensation",
      label: "Owner's Compensation Recommended Standard",
    },
  ];
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  useEffect(() => {
    setData(null);
    dispatch(
      settingFetch({
        group: "standard",
        onSuccess: (data) => setData(data),
        onError: () => setData(false),
      })
    );
  }, []);

  if (data == null) {
    return (
      <Alert severity="info">
        <AlertTitle>Loading Settings</AlertTitle>Please wait the settings are
        loaded
      </Alert>
    );
  }
  if (data == false) {
    return (
      <Alert severity="error">
        <AlertTitle>Error loading Settings</AlertTitle>
        An unexpected error occurred while loading the settings
      </Alert>
    );
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        standard_retain: "",
        standard_convert_consultation: "",
        standard_convert_trial: "",
        standard_attract_consultation: "",
        standard_attract_trial: "",
        standard_leverage: "",
        standard_ownerscompensation: "",
        ...data,
      }}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(data, { setSubmitting, resetForm }) => {
        dispatch(settingStandardSave(data));
      }}
    >
      {(props) => {
        return (
          <Box component="form" onSubmit={props.handleSubmit} sx={{}}>
            <FormMaker fields={fields} {...props} />
            <Button
              sx={{ mt: 2 }}
              variant="contained"
              type="submit"
              color="secondary"
            >
              save Standard Settings
            </Button>
          </Box>
        );
      }}
    </Formik>
  );
};
