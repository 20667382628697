import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { loadLocationData } from "../../actions";
import {
  Header,
  InfoGrid,
  DataFilter,
  DataHolder,
  ErrorBoundary,
} from "../../components";
import { Practices } from "./practices";

const LocationDataView = () => {
  const dispatch = useDispatch();
  const [tableMode, setTableMode] = useState(true);
  const [hideBestPractices, setHideBestPractices] = useState(true);
  const [filter, setFilter] = useState({
    period: "month",
  });
  const [data, setData] = useState({});

  const downloadData = (downloadOption) => {
    dispatch(
      loadLocationData({
        filter: { ...filter, download: downloadOption },
        onSuccess: (data) => {},
        onError: () => {},
      })
    );
  };
  const loadData = (filter) => {
    setData(null);
    setTableMode(true);
    dispatch(
      loadLocationData({
        filter,
        onSuccess: (data) => setData(data),
        onError: () => setData(false),
      })
    );
  };
  return (
    <ErrorBoundary>
      <Box>
        <Header heading={"Location Data View"}>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => setHideBestPractices(!hideBestPractices)}
          >
            {hideBestPractices ? "Show" : "Hide"} Best Practices
          </Button>
        </Header>
        <Practices hide={hideBestPractices} />
        <DataFilter
          filter={filter}
          setFilter={setFilter}
          loadData={loadData}
          tableMode={tableMode}
          toggleTableMode={() => setTableMode(!tableMode)}
        />
        <InfoGrid data={data} />
        <DataHolder
          filter={filter}
          downloadData={downloadData}
          tableMode={tableMode}
          data={data}
        />
      </Box>
    </ErrorBoundary>
  );
};

export default LocationDataView;
