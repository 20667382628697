import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { useNavigate } from "react-router";

const Header = ({
  heading,
  subHeading,
  backPath,
  onReload = null,
  children,
}) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{ pb: 2 }}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Box>
        <Typography display={"inline"} variant="h5" fontWeight={"bold"}>
          {heading}
        </Typography>
        <Typography
          sx={(theme) => ({ color: theme.palette.grey[600], pl: 1 })}
          display={"inline"}
          variant="overline"
          fontWeight={"bold"}
        >
          {subHeading}
        </Typography>
      </Box>
      <Box>
        {backPath && (
          <Button
            variant="outlined"
            startIcon={<ChevronLeft />}
            onClick={() => {
              navigate(backPath);
            }}
          >
            Back
          </Button>
        )}
        {children}
      </Box>
    </Box>
  );
};

export { Header };
