import { Download } from "@mui/icons-material";
import {
  Box,
  Paper,
  Typography,
  Alert,
  AlertTitle,
  Divider,
  Button,
  Popper,
  Grow,
  ClickAwayListener,
  MenuItem,
  MenuList,
} from "@mui/material";

import React, { useState } from "react";
import {
  FinanceTable,
  OperationTable,
  LeverageTable,
  FinanceCharts,
  AllLocationData,
} from ".";

export const DataHolder = ({
  data,
  filter,
  tableMode,
  downloadData,
  ranked,
  leaderboard,
}) => {
  // throw false;
  if (data === false) {
    return (
      <Paper sx={{ p: 2, mt: 2 }} variant="outlined">
        <Alert severity="error">
          <AlertTitle>Error fetching Data</AlertTitle>
          An error occurred while fetching data!
        </Alert>
      </Paper>
    );
  }
  if (data === null) {
    return (
      <Paper sx={{ p: 2, mt: 2 }} variant="outlined">
        <Alert severity="info">
          <AlertTitle>Loading Data</AlertTitle>
          Please wait while we fetch the data for you
        </Alert>
      </Paper>
    );
  }
  if (data == undefined || !data.rows || data.rows.length == 0) return null;
  const header = (
    <>
      <Box sx={{ p: 2 }} display="flex" justifyContent="space-between">
        <Typography variant={"h5"} fontWeight="bold">
          {filter && filter.dataViewMode.toUpperCase()} DATA
        </Typography>
        <DownloadButton downloadData={downloadData} />
      </Box>
      <Divider />
    </>
  );
  return (
    <Box sx={{ pt: 2 }}>
      {filter.dataViewMode == "all" && tableMode && (
        <AllLocationData
          ranked={ranked}
          profileWise={filter.profileWise}
          data={data.rows}
          header={header}
          monthly={filter.period == "monthRange"}
          leaderboard={leaderboard}
        />
      )}
      {filter.dataViewMode == "finances" && tableMode && (
        <FinanceTable
          ranked={ranked}
          profileWise={filter.profileWise}
          data={data.rows}
          header={header}
          monthly={filter.period == "monthRange"}
          leaderboard={leaderboard}
        />
      )}
      {filter.dataViewMode == "finances" && !tableMode && (
        <FinanceCharts
          ranked={ranked}
          profileWise={filter.profileWise}
          data={data.rows}
          monthly={filter.period == "monthRange"}
          leaderboard={leaderboard}
        />
      )}
      {filter.dataViewMode == "operations" && (
        <OperationTable
          ranked={ranked}
          profileWise={filter.profileWise}
          data={data.rows}
          header={header}
          monthly={filter.period == "monthRange"}
          leaderboard={leaderboard}
        />
      )}
      {filter.dataViewMode == "leverage" && (
        <LeverageTable
          ranked={ranked}
          profileWise={filter.profileWise}
          data={data.rows}
          header={header}
          monthly={filter.period == "monthRange"}
          leaderboard={leaderboard}
        />
      )}
    </Box>
  );
};

export const DownloadButton = ({ downloadData }) => {
  const [showOptions, setShowOptions] = useState(false);
  const anchorRef = React.useRef(null);

  return (
    <>
      <Button
        ref={anchorRef}
        onClick={() => setShowOptions(!showOptions)}
        variant="outlined"
        startIcon={<Download />}
      >
        Download Data
      </Button>
      <Popper open={showOptions} anchorEl={anchorRef.current} transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setShowOptions(false)}>
                <MenuList autoFocusItem={showOptions}>
                  <MenuItem onClick={() => downloadData("excel")}>
                    Excel
                  </MenuItem>
                  <MenuItem onClick={() => downloadData("csv")}>CSV</MenuItem>
                  <MenuItem onClick={() => downloadData("json")}>JSON</MenuItem>
                  <MenuItem onClick={() => downloadData("pdf")}>PDF</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
