import React from "react";
import { Snackbar, Alert, Slide } from "@mui/material";
import { connect } from "react-redux";
import { appHideNotification } from "../actions";

let Notification = ({
  notificationDisplayed,
  appHideNotification,
  type,
  text,
}) => {
  console.log({
    notificationDisplayed,
    appHideNotification,
    type,
    text,
  });

  return (
    <Snackbar
      style={{ zIndex: 1000000 }}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={notificationDisplayed}
      autoHideDuration={3000}
      onClose={appHideNotification}
      severity={type}
      message={text}
      TransitionComponent={Slide}
    >
      <Alert
        onClose={appHideNotification}
        severity={type}
        sx={{ minWidth: "300px" }}
      >
        {text}
      </Alert>
    </Snackbar>
  );
};

const mstp = ({
  app: {
    notificationDisplayed,
    notificationInfo: { type, text },
  },
}) => {
  return { notificationDisplayed, type, text };
};

Notification = connect(mstp, { appHideNotification })(Notification);

export { Notification };
