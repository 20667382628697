import { PureComponent, Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Routes,
  useLocation,
} from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { appCheckLogin } from "./actions";
import { Layout } from "./components";
import LocationDataView from "./pages/locationDataView";
import LeaderboardPage from "./pages/leaderboard";
import UserManagementPage from "./pages/userManagement";
import SettingPage from "./pages/settings";

const LoginPage = lazy(() => import("./pages/login"));
const HomePage = lazy(() => import("./pages/home"));
const SetupPage = lazy(() => import("./pages/setup"));
const ProfilePage = lazy(() => import("./pages/profile"));
const LocationsPage = lazy(() => import("./pages/location"));
const LocationFormPage = lazy(() => import("./pages/locationForm"));
const LocationDataAddPage = lazy(() => import("./pages/locationDataAdd"));
const FeedbackPage = lazy(() => import("./pages/feedback"));
const EducationPage = lazy(() => import("./pages/education"));
const SupportPage = lazy(() => import("./pages/support"));

const Private = ({ children, isAdminOnly, ...rest }) => {
  const { logged, isAdmin } = useSelector(
    ({
      app: { logged },
      profile: {
        data: { isAdmin },
      },
    }) => ({ logged, isAdmin })
  );
  if (!logged) return <Navigate to="/login" />;
  if (isAdminOnly && !isAdmin) return <Navigate to="/" />;
  return children;
};

const Public = ({ children, ...rest }) => {
  const logged = useSelector(({ app }) => app.logged);
  return !logged ? children : <Navigate to="/" />;
};

export const RemoveTrailingSlash = ({ ...rest }) => {
  const location = useLocation();
  if (location.pathname.match("/.*/$")) {
    return (
      <Navigate
        replace
        {...rest}
        to={{
          pathname: location.pathname.replace(/\/+$/, ""),
          search: location.search,
        }}
      />
    );
  } else return null;
};

class App extends PureComponent {
  componentDidMount() {
    this.props.appCheckLogin();
  }

  render() {
    return (
      <Router>
        <Layout>
          <Suspense fallback={<div>Loading...</div>}>
            <RemoveTrailingSlash />
            <Routes>
              <Route
                exact
                path="/login"
                element={
                  <Public>
                    <LoginPage />
                  </Public>
                }
              />
              <Route
                exact
                path="/"
                element={
                  <Private>
                    <HomePage />
                  </Private>
                }
              />
              <Route
                exact
                path="/setup"
                element={
                  <Private>
                    <SetupPage />
                  </Private>
                }
              />
              <Route
                exact
                path="/profile"
                element={
                  <Private>
                    <ProfilePage />
                  </Private>
                }
              />
              <Route
                exact
                path="/locations"
                element={
                  <Private>
                    <LocationsPage />
                  </Private>
                }
              />
              <Route
                exact
                path="/locations/add"
                element={
                  <Private>
                    <LocationFormPage mode="add" />
                  </Private>
                }
              />
              <Route
                exact
                path="/locations/update/:id"
                element={
                  <Private>
                    <LocationFormPage mode="update" />
                  </Private>
                }
              />
              <Route
                exact
                path="/data/add"
                element={
                  <Private>
                    <LocationDataAddPage />
                  </Private>
                }
              />
              <Route
                exact
                path="/data"
                element={
                  <Private>
                    <LocationDataView />
                  </Private>
                }
              />
              <Route
                exact
                path="/leaderboard"
                element={
                  <Private>
                    <LeaderboardPage />
                  </Private>
                }
              />
              <Route
                exact
                path="/users"
                element={
                  <Private isAdminOnly>
                    <UserManagementPage />
                  </Private>
                }
              />
              <Route
                exact
                path="/settings"
                element={
                  <Private isAdminOnly>
                    <SettingPage />
                  </Private>
                }
              />
              <Route
                exact
                path="/education"
                element={
                  <Private>
                    <EducationPage />
                  </Private>
                }
              />
              <Route
                exact
                path="/support"
                element={
                  <Private>
                    <SupportPage />
                  </Private>
                }
              />
              <Route
                exact
                path="/feedback"
                element={
                  <Private>
                    <FeedbackPage />
                  </Private>
                }
              />
              <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
          </Suspense>
        </Layout>
      </Router>
    );
  }
}

export default connect(null, { appCheckLogin })(App);
