import React, { useEffect } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material"

const ConfirmationDialog = ({
  title,
  content,
  onCancel,
  onAccept,
  open,
  onAcceptText,
}) => {
  return (
    <Dialog
      sx={{ "& .MuiPaper-root": { width: 500 } }}
      open={open}
      onClose={onCancel}
    >
      <DialogTitle>{title}</DialogTitle>
      {Boolean(content) && (
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onCancel} variant="outlined">
          Cancel
        </Button>
        <Button onClick={onAccept} variant="contained" color="error">
          {onAcceptText ? onAcceptText : "Agree"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export const DeleteDialog = ({
  formManager,
  onDelete,
  deleteFunction,
  objectName = "Object",
  afterDelete,
  content,
}) => {
  return (
    <ConfirmationDialog
      open={Boolean(formManager.get("delete"))}
      onCancel={() => formManager.hide("delete")}
      onAccept={() => {
        deleteFunction && deleteFunction()
        onDelete &&
          onDelete({
            id: formManager.get("delete").id,
            onSuccess: () => {
              formManager.hide("hide")
              afterDelete && afterDelete()
            },
          })
      }}
      onAcceptText="Delete"
      title={`Confirm Deletion of ${objectName}`}
      content={content(formManager.get("delete").name)}
    />
  )
}

export { ConfirmationDialog }
