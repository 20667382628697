import {
  APP_SET_LOGIN,
  PROFILE_SET_DATA,
  PROFILE_HAS_COMPLETED_SETUP,
} from "../actions/types";

const initialState = {
  data: {},
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case APP_SET_LOGIN:
    case PROFILE_SET_DATA:
      return { ...state, data: action.payload };
    case PROFILE_HAS_COMPLETED_SETUP:
      return { ...state, data: { ...state.data, hasCompletedSetup: true } };
    default:
      return state;
  }
};

export { profileReducer };
