import { apiCaller } from "../utils/generalUtils";
import {
  appStartLoading,
  appStopLoading,
  showSuccessMessage,
  showErrorMessageFromAPI,
} from "../actions";
import { PROFILE_HAS_COMPLETED_SETUP, PROFILE_SET_DATA } from "./types";

export const profileSetup = ({ data, onSuccess, onError }) => {
  return async (dispatch) => {
    try {
      apiCaller
        .post("/profile/setup", data)
        .then(({ data: newData }) => {
          dispatch({
            type: PROFILE_SET_DATA,
            payload: newData.data,
          });
          onSuccess();
        })
        .catch((err) => {
          onError();
          dispatch(showErrorMessageFromAPI({ err }));
        });
    } catch (err) {
      onError();
      dispatch(showErrorMessageFromAPI({ err }));
    }
  };
};

export const profileUpdatePassword = ({ data, onSuccess }) => {
  return async (dispatch) => {
    try {
      dispatch(appStartLoading());
      apiCaller
        .post("/profile/changePassword", data)
        .then(() => {
          dispatch(showSuccessMessage("Password updated"));
          onSuccess();
        })
        .catch((err) => {
          dispatch(showErrorMessageFromAPI({ err }));
        })
        .finally(() => {
          dispatch(appStopLoading());
        });
    } catch (err) {
      dispatch(appStopLoading());
      dispatch(showErrorMessageFromAPI({ err }));
    }
  };
};

export const profileUpdateData = ({ data, onSuccess }) => {
  return async (dispatch) => {
    try {
      dispatch(appStartLoading());
      apiCaller
        .post("/profile/update", data)
        .then(({ data }) => {
          dispatch(showSuccessMessage("Profile updated"));
          dispatch({
            type: PROFILE_SET_DATA,
            payload: data.data,
          });
          onSuccess();
        })
        .catch((err) => {
          dispatch(showErrorMessageFromAPI({ err }));
        })
        .finally(() => {
          dispatch(appStopLoading());
        });
    } catch (err) {
      dispatch(appStopLoading());
      dispatch(showErrorMessageFromAPI({ err }));
    }
  };
};

export const profileHasCompletedSetup = () => {
  return {
    type: PROFILE_HAS_COMPLETED_SETUP,
  };
};
