import React from "react";
import { Stack, IconButton, Tooltip } from "@mui/material";
import {
  Delete as DeleteIcon,
  Edit as UpdateIcon,
  Preview as ViewIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router";

export const TableActionButtons = ({
  children,
  data,
  path,
  formManager,
  loading,
  id,
  nameId,
  showView = true,
  showDelete = true,
  showUpdate = true,
  actionVisibility = {},
  extraButtons = [],
  navigateOnUpdate = null,
  ...props
}) => {
  const navigate = useNavigate();
  let BUTTONS = [
    ...extraButtons,
    {
      show: showView,
      tooltip: "View",
      onClick: () => {
        navigate(path);
      },
      icon: <ViewIcon fontSize="inherit" />,
      priority: 100,
    },
    {
      show: showUpdate,
      tooltip: "Update",
      onClick: () => {
        if (navigateOnUpdate) {
          navigate(navigateOnUpdate);
        } else {
          formManager.show("update", data);
        }
      },
      icon: <UpdateIcon fontSize="inherit" />,
      priority: 200,
    },
    {
      show: showDelete,
      tooltip: "Delete",
      onClick: () => {
        formManager.show("delete", {
          id,
          name: data[nameId],
        });
      },
      icon: <DeleteIcon fontSize="inherit" />,
      priority: 300,
    },
  ];
  BUTTONS = BUTTONS.sort((a, b) =>
    a.priority > b.priority ? 1 : b.priority > a.priority ? -1 : 0
  );

  return (
    <Stack direction="row" spacing={0.5}>
      {children}
      {BUTTONS.map(({ show, tooltip, onClick, icon }, index) => {
        if (!show) return null;
        return (
          <IconButton
            key={`actionButton_${index}`}
            size="small"
            onClick={onClick}
            disabled={loading}
          >
            <Tooltip arrow title={tooltip}>
              {icon}
            </Tooltip>
          </IconButton>
        );
      })}
    </Stack>
  );
};
