import {
  APP_HIDE_NOTIFICATION,
  APP_SHOW_NOTIFICATION,
  APP_SET_LOGIN,
  APP_START_LOADING,
  APP_STOP_LOADING,
  APP_SWITCH_DARK_MODE,
  APP_TOGGLE_SIDEMENU,
} from "../actions/types";

const initialState = {
  logged: false,
  loading: false,
  sessionChecked: false,
  errorMessage: null,
  notificationDisplayed: false,
  notificationInfo: {},
  darkMode: localStorage.getItem("profitmadesimpledarkmode") === "true",
  showSideMenu: true,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case APP_TOGGLE_SIDEMENU: {
      return { ...state, showSideMenu: !state.showSideMenu };
    }
    case APP_SWITCH_DARK_MODE: {
      localStorage.setItem(
        "profitmadesimpledarkmode",
        Boolean(!state.darkMode)
      );
      return { ...state, darkMode: !state.darkMode };
    }
    case APP_START_LOADING: {
      const newState_a = { ...state };
      newState_a.loading = true;
      return newState_a;
    }
    case APP_STOP_LOADING: {
      const newState_b = { ...state };
      newState_b.loading = false;
      return newState_b;
    }
    case APP_SET_LOGIN:
      const newState_c = { ...state };
      if (action.payload !== false) {
        newState_c.logged = true;
      }
      newState_c.sessionChecked = true;
      return newState_c;
    case APP_SHOW_NOTIFICATION:
      return {
        ...state,
        notificationInfo: action.payload,
        notificationDisplayed: true,
      };
    case APP_HIDE_NOTIFICATION:
      return { ...state, notificationDisplayed: false };
    default:
      return state;
  }
};

export { appReducer };
