import { HOME_SET_DATA } from "../actions/types";

const initialState = {
  data: [],
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case HOME_SET_DATA:
      return { ...state, data: action.payload };
    default:
      return state;
  }
};

export { homeReducer };
