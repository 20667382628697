import { useState } from "react"
import queryString from "query-string"

export const useTableManager = (setup, options = {}) => {
  const initialData = {
    error: false,
    sort: {},
    filterModel: {},
    page: 0,
    pageSize: 50,
    rowCount: 0,
  }
  const dataToFeed = { ...initialData, ...setup }
  const [destroyed, setDestroyed] = useState(false)
  const [data, setData] = useState(dataToFeed)
  const [loading, setDataLoading] = useState(
    options.loading !== undefined ? options.loading : true
  )
  const [change, setChange] = useState(0)

  return {
    change: () => change,

    get: () => data,
    set: update => {
      setChange(change + 1)
      setData({ ...data, ...update })
    },

    destroy: () => setDestroyed(true),

    getSortField: () => (data.sort.field ? data.sort.field : ""),
    getSortOrder: () => (data.sort.order ? data.sort.order : ""),

    getQuery: () => {
      let { page, pageSize, sort, filterModel } = data
      if (filterModel) {
        for (let key of Object.keys(filterModel)) {
          if (filterModel[key] === "" || filterModel[key] === null) {
            delete filterModel[key]
          }
        }
      }
      let sortQuery = {}
      if (sort && sort.field) {
        sortQuery = {
          sortField: sort.field,
          sortOrder: sort.order,
        }
      }
      return queryString.stringify({
        page,
        pageSize,
        ...sortQuery,
        ...filterModel,
      })
    },

    getError: () => data.error,
    setError: error => {
      if (destroyed) return
      setData({ ...data, error: error })
    },

    getLoading: () => loading,
    setLoading: load => {
      if (destroyed) return
      setDataLoading(load)
    },

    getRowCount: () => data.rowCount,
    setRowCount: count => {
      if (destroyed) return
      setData({ ...data, rowCount: count })
    },

    getSortModel: () => data.sortModel,
    setSortModel: model => {
      if (loading) return
      if (destroyed) return
      setData({ ...data, sortModel: [model[0]] })
      setChange(change + 1)
    },

    getFilterModel: () => data.filterModel,
    setFilterModel: model => {
      if (loading) return
      if (destroyed) return
      setData({ ...data, filterModel: model })
      setChange(change + 1)
    },

    getPage: () => data.page,
    setPage: number => {
      if (loading) return
      if (destroyed) return
      setChange(change + 1)
      setData({ ...data, page: number })
    },

    getPageSize: () => data.pageSize,
    setPageSize: size => {
      if (loading) return
      if (destroyed) return
      setChange(change + 1)
      setData({ ...data, pageSize: size })
    },
  }
}
