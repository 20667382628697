import React, { useState } from "react";
import { Box, Paper, Tab, Tabs } from "@mui/material";
import { ErrorBoundary, Header } from "../../components";
import { TabContext, TabPanel } from "@mui/lab";
import { SMTPTab } from "./smtpTab";
import { StandardTab } from "./standardTab";

const SettingPage = () => {
  const [tab, setTab] = useState(0);
  return (
    <ErrorBoundary>
      <Box>
        <Header heading={"Settings"} />
        <Paper variant="outlined" sx={{ p: 2 }}>
          <Tabs
            value={tab}
            onChange={(_, x) => setTab(x)}
            aria-label="wrapped label tabs example"
          >
            <Tab value={0} label="SMTP" />
            <Tab value={1} label="Standard Values" />
          </Tabs>
          <TabContext value={tab}>
            <TabPanel value={0} index={0}>
              <SMTPTab />
            </TabPanel>
            <TabPanel value={1} index={1}>
              <StandardTab />
            </TabPanel>
          </TabContext>
        </Paper>
      </Box>
    </ErrorBoundary>
  );
};

export default SettingPage;
