import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { connect } from "react-redux";
import reduxThunk from "redux-thunk";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";

import App from "./App";
import reducers from "./reducers";

const store = createStore(reducers, applyMiddleware(reduxThunk));
const Render = connect(
  ({ app }) => ({ darkMode: app.logged ? app.darkMode : false }),
  {}
)(({ darkMode }) => {
  return (
    <ThemeProvider
      theme={createTheme({
        palette: {
          mode: darkMode ? "dark" : "light",
          background: {
            default: darkMode ? "#080808" : "#f1f1f1",
          },
          primary: {
            main: "#2598f5",
          },
          secondary: {
            main: "#F58225",
          },
        },
      })}
    >
      <CssBaseline />
      <App />
    </ThemeProvider>
  );
});

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <Render />
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
