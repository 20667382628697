import { RemoveCircle as ActivityToggleIcon } from "@mui/icons-material";
import { Chip } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userFetch } from "../../actions";
import { TableActionButtons } from "../../components/TableActionButtons";
import { NormalTable } from "../../components/Tables";
import { useTableManager } from "../../hooks/useTableManager";

const UserTable = ({ formManager }) => {
  const tableManager = useTableManager(
    {
      sort: { field: "email", order: "asc" },
    },
    { loading: false }
  );
  const dispatch = useDispatch();
  const { data, loading } = useSelector(({ users: { data, loading } }) => ({
    data,
    loading,
  }));
  const columns = useMemo(
    () => [
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Full Name",
        accessor: "userName",
      },
      {
        Header: "Business Name",
        accessor: "businessName",
      },
      {
        Header: "Status",
        accessor: "active",
        Cell: ({ value }) => (
          <Chip
            color={value ? "success" : "warning"}
            label={value ? "ACTIVE" : "INACTIVE"}
          />
        ),
      },
      {
        accessor: "actions",
        Header: "Actions",
        width: "1%",
        Cell: ({ row }) => {
          const { id, original } = row;
          return (
            <TableActionButtons
              data={original}
              showView={false}
              id={id}
              loading={loading}
              formManager={formManager}
              nameId={"email"}
              extraButtons={[
                {
                  show: true,
                  tooltip: original.active ? "Block" : "Activate",
                  onClick: () => {
                    formManager.show("archive", {
                      id,
                      status: original.active,
                      name: original.email,
                    });
                  },
                  icon: <ActivityToggleIcon fontSize="inherit" />,
                  priority: 250,
                },
              ]}
            />
          );
        },
      },
    ],
    [formManager, loading]
  ); // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    dispatch(userFetch());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <NormalTable
      formOpened={
        formManager.get("add") ||
        Boolean(formManager.get("update")) ||
        Boolean(formManager.get("delete")) ||
        Boolean(formManager.get("archive"))
      }
      tableManager={tableManager}
      getRowId={(row) => row.id}
      columns={columns}
      loading={loading}
      data={data}
    />
  );
};

export default UserTable;
