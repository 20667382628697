import React, { useState } from "react"
export const useFormManager = () => {
  const [status, setStatus] = useState()
  const initialState = {
    showAddForm: false,
    showUpdateForm: false,
    showDeleteDialog: false,
    showToggleDialog: false,
    showArchiveForm: false,
    showFilter: false,
  }
  if (!status) {
    setStatus(initialState)
  }
  return {
    get: form => {
      if (!status) {
        return false
      }
      switch (form) {
        case "add":
          return status.showAddForm
        case "archive":
          return status.showArchiveForm
        case "filter":
          return status.showFilter
        case "update":
          return status.showUpdateForm
        case "delete":
          return status.showDeleteDialog
        case "toggle":
          return status.showToggleDialog
        default:
          break
      }
    },
    show: (form, data = null) => {
      switch (form) {
        case "add":
          setStatus({ ...status, showAddForm: data || true })
          break
        case "filter":
          setStatus({ ...status, showFilter: true })
          break
        case "update":
          setStatus({ ...status, showUpdateForm: data })
          break
        case "delete":
          setStatus({ ...status, showDeleteDialog: data })
          break
        case "archive":
          setStatus({ ...status, showArchiveForm: data })
          break
        case "toggle":
          setStatus({ ...status, showToggleDialog: data })
          break
        default:
          break
      }
    },
    hide: form => {
      switch (form) {
        case "add":
          setStatus({ ...status, showAddForm: false })
          break
        case "filter":
          setStatus({ ...status, showFilter: false })
          break
        case "update":
          setStatus({ ...status, showUpdateForm: false })
          break
        case "delete":
          setStatus({ ...status, showDeleteDialog: false })
          break
        case "archive":
          setStatus({ ...status, showArchiveForm: false })
          break
        default:
          setStatus({ ...initialState, showFilter: status.showFilter })
          break
      }
    },
    toggle: form => {
      if (!status) {
        return false
      }
      switch (form) {
        case "filter":
          setStatus({ ...status, showFilter: !status.showFilter })
          break
        default:
          break
      }
    },
  }
}
