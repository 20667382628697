import React from "react";
import { Paper, Box, Typography, Grid, Stack } from "@mui/material";
import ReactSpeedometer from "react-d3-speedometer";
import { useTheme } from "@mui/system";

export const InfoCard = (props) => {
  const theme = useTheme();
  const { error, primary, secondary, augmentColor, text } = theme.palette;
  return (
    <Paper variant="outlined" sx={{ width: "500px", overflow: "hidden" }}>
      <Box
        sx={{
          backgroundColor: primary.main,
          color: "white",
        }}
      >
        <Typography variant="button" sx={{ p: 2, fontSize: "1.1rem" }}>
          {props.title}
        </Typography>
      </Box>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <Box sx={{ width: "100%", height: "170px" }}>
              <ReactSpeedometer
                maxValue={parseInt(
                  props.currentResult > props.desiredGoal
                    ? props.currentResult
                    : props.desiredGoal * 1.1
                )}
                ringWidth={30}
                fluidWidth={true}
                value={parseInt(props.currentResult)}
                needleColor={error.dark}
                startColor="red"
                maxSegmentLabels={10}
                segments={1000}
                endColor="green"
              />
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Stack spacing={2}>
              <TextHeader
                title={"Current Result"}
                value={props.currentResult}
              />
              <TextHeader
                title={"Effectiveness"}
                value={props.effectiveness + "%"}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <TextHeader title={"Desired Goal"} value={props.desiredGoal} />
          </Grid>
          <Grid item xs={6}>
            <TextHeader
              title={"Recommended Standard"}
              value={props.recommendedStandard}
            />
          </Grid>
          <Grid item xs={6}>
            <TextHeader title={"Result of Leader"} value={props.leaderValue} />
          </Grid>
          <Grid item xs={6}>
            <TextHeader title={"System Rank"} value={props.rank} />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

const TextHeader = ({ title, value }) => {
  return (
    <Paper variant="outlined" sx={{ p: 0.5, pl: 1, pr: 1 }}>
      <Typography variant="caption">{title}</Typography>
      <Typography variant="h6" sx={{ lineHeight: "1.5rem" }}>
        {value}
      </Typography>
    </Paper>
  );
};
