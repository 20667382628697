import { USERTYPE_ADD_DATA, USERTYPE_SET_LOADING } from "../actions/types";

const initialState = {
  data: [],
  loading: false,
};

const userTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case USERTYPE_SET_LOADING: {
      return { ...state, loading: action.payload };
    }
    case USERTYPE_ADD_DATA: {
      return { ...state, data: action.payload };
    }
    default:
      return state;
  }
};

export { userTypeReducer };
