import { apiCaller, fileDownloadURL } from "../utils/generalUtils";
import { LOCATION_DATA_ADD_PERIOD_DATA } from "./types";
import {
  appStartLoading,
  appStopLoading,
  showSuccessMessage,
  showErrorMessageFromAPI,
} from ".";

export const locationDataPeriodFetch = ({ data, onSuccess, onError }) => {
  return (dispatch) => {
    try {
      const query = new URLSearchParams(data).toString();
      dispatch(appStartLoading());
      apiCaller
        .get(`/locationData/period?${query}`)
        .then(({ data }) => {
          onSuccess(data.data);
        })
        .catch((err) => {
          onError();
          dispatch(showErrorMessageFromAPI({ err }));
        })
        .finally(() => {
          dispatch(appStopLoading());
        });
    } catch (err) {
      dispatch(appStopLoading());
      dispatch(showErrorMessageFromAPI({ err }));
    }
  };
};

export const locationDataAdd = ({ data }) => {
  return (dispatch) => {
    try {
      dispatch(appStartLoading());
      apiCaller
        .post(`/locationData/${data.period}`, data)
        .then(({ data }) => {
          dispatch(showSuccessMessage("Data successfully added"));
        })
        .catch((err) => {
          dispatch(showErrorMessageFromAPI({ err }));
        })
        .finally(() => {
          dispatch(appStopLoading());
        });
    } catch (err) {
      dispatch(appStopLoading());
      dispatch(showErrorMessageFromAPI({ err }));
    }
  };
};

export const loadLocationData = ({
  leaderboard,
  filter,
  onSuccess,
  onError,
}) => {
  return (dispatch) => {
    try {
      Object.keys(filter).map((key) => {
        if (!filter[key] && filter[key] !== false) delete filter[key];
      });
      const query = new URLSearchParams(filter).toString();
      if (filter.download) {
        fileDownloadURL(
          `/${leaderboard ? "leaderboard" : "locationData"}?${query}`
        );
      } else {
        dispatch(appStartLoading());
        apiCaller
          .get(`/${leaderboard ? "leaderboard" : "locationData"}?${query}`)
          .then(({ data }) => {
            onSuccess(data);
          })
          .catch((err) => {
            onError();
            dispatch(showErrorMessageFromAPI({ err }));
          })
          .finally(() => {
            dispatch(appStopLoading());
          });
      }
    } catch (err) {
      dispatch(appStopLoading());
      dispatch(showErrorMessageFromAPI({ err }));
    }
  };
};
