import { useEffect } from "react";
import { Formik } from "formik";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import {
  FormMaker,
  FormModal,
  UserTypeSelector,
  FormikTextField,
  YearSelector,
} from "../../components";
import { UserBaseSchema } from "./schema";
import { useDispatch } from "react-redux";
import generator from "generate-password-browser";

import { userUpdate, userCreate } from "../../actions";
import { Password } from "@mui/icons-material";

const initialFormValues = {
  userName: "",
  email: "",
  userDesignation: "",
  businessName: "",
  yearEstablished: "",
  password: "",
  UserTypeId: "",
};

const FormFields = (props) => {
  const fields = [
    {
      id: "userName",
      label: "Full Name",
    },
    {
      id: "email",
      type: "email",
    },
    {
      id: "userDesignation",
      label: "role",
    },
    {
      id: "businessName",
    },
    {
      type: "component",
      component: (props, values) => (
        <YearSelector id="yearOfEstablishment" {...props} />
      ),
    },
    {
      type: "component",
      component: (props, values) => <UserTypeSelector {...props} />,
    },
    {
      type: "component",
      component: (props, values) => (
        <FormikTextField
          id={"password"}
          label="Password (leave empty for auto-generation)"
          {...props}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="Generate Password" arrow>
                  <IconButton
                    onClick={() =>
                      props.setFieldValue(
                        "password",
                        generator.generate({
                          length: 10,
                          numbers: true,
                          strict: true,
                        })
                      )
                    }
                  >
                    <Password />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      ),
    },
  ];
  return <FormMaker {...props} fields={fields} />;
};

const UserForm = ({ open, onClose, updateData, ...props }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      //for cleanup
    };
  });
  const forUpdate = typeof updateData == "object";
  return (
    <FormModal
      open={open}
      onClose={onClose}
      width={700}
      header={`${forUpdate ? "Update" : "Create"} User`}
    >
      <Formik
        enableReinitialize
        initialValues={forUpdate ? updateData : initialFormValues}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={UserBaseSchema}
        onSubmit={(data, { setSubmitting, resetForm }) => {
          const actionData = {
            data,
            onSuccess: () => {
              onClose();
              resetForm();
            },
            onFinally: () => {
              setSubmitting(false);
            },
          };
          if (forUpdate) {
            dispatch(userUpdate(actionData));
          } else {
            dispatch(userCreate(actionData));
          }
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => {
          return (
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{
                "& > div:not(:last-child)": { pb: 2 },
              }}
            >
              <FormFields
                values={values}
                handleBlur={handleBlur}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                errors={errors}
              />
              <Button variant="contained" size="large" type="submit" fullWidth>
                {forUpdate ? "Update" : "Create"}
              </Button>
            </Box>
          );
        }}
      </Formik>
    </FormModal>
  );
};

export default UserForm;
