import React from "react";
import { PieChart, Pie, ResponsiveContainer, Legend, Cell } from "recharts";
import { Paper, Grid, Typography, Divider } from "@mui/material";
const COLORS = [
  "#DFFF00",
  "#FFBF00",
  "#FF7F50",
  "#DE3163",
  "#9FE2BF",
  "#40E0D0",
  "#6495ED",
  "#CCCCFF",
];
const FinanceCharts = ({ data }) => {
  return (
    <Paper variant="outlined" sx={{ p: 2 }}>
      <Grid container spacing={2}>
        {data.map(
          (
            {
              tag,
              rank,
              businessName,
              totalMarketingSpend,
              totalOperatingExpenses,
              totalEquipmentAnnualExpenses,
              totalPayroll,
              totalExpense,
            },
            index
          ) => {
            const marketingVal = (
              (100 * totalMarketingSpend) /
              totalExpense
            ).toFixed(0);
            const equipmentVal = (
              (100 * totalEquipmentAnnualExpenses) /
              totalExpense
            ).toFixed(0);
            const operatingVal = (
              (100 * totalOperatingExpenses) /
              totalExpense
            ).toFixed(0);
            const payrollVal = ((100 * totalPayroll) / totalExpense).toFixed(0);
            let data = [
              {
                name: "Marketing %",
                value: parseFloat(marketingVal),
              },
              {
                name: "Equipment/Annual %",
                value: parseFloat(equipmentVal),
              },
              {
                name: "Operating %",
                value: parseFloat(operatingVal),
              },
              {
                name: "Payroll %",
                value: parseFloat(payrollVal),
              },
            ];
            const title = [];
            if (businessName) title.push(businessName);
            if (tag) title.push(tag);
            if (rank) title.push("Rank " + rank);
            return (
              <Grid item xs={6} key={index}>
                <Paper sx={{ p: 2 }} variant="outlined">
                  <Typography variant="button">{title.join(" - ")}</Typography>
                  <Typography variant="body1">Expense Chart</Typography>
                  <Divider />
                  <ResponsiveContainer width={"100%"} height={400}>
                    <PieChart>
                      <Legend verticalAlign="top" height={36} />
                      <Pie
                        data={data}
                        dataKey="value"
                        nameKey="name"
                        outerRadius={100}
                        label
                      >
                        {data.map((entry, index) => {
                          return (
                            <Cell key={`cell-${index}`} fill={COLORS[index]} />
                          );
                        })}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </Paper>
              </Grid>
            );
          }
        )}
      </Grid>
    </Paper>
  );
};

export { FinanceCharts };
