import { Chip, Typography } from "@mui/material";
import moment from "moment";
import React, { useMemo } from "react";
import { NormalTable } from ".";
import { useTableManager } from "../hooks";

const commonColumns = ({ ranked, monthly, leaderboard, profileWise }) => {
  try {
    let columns = [];
    if (ranked) {
      columns.push({
        Header: "Rank",
        accessor: "rank",
        width: "1%",
        Cell: ({ value }) => {
          if (leaderboard && value <= 3) {
            return value;
          }
          return value;
        },
      });
    }
    if (monthly) {
      columns.push({
        Header: "Month",
        accessor: "monthYear",
        type: "date",
        Cell: ({ value }) => moment(value).format("MMM, YY"),
      });
    }
    if (profileWise) {
      columns.push({
        width: "15%",
        Header: "Business Name",
        accessor: "businessName",
      });
    } else {
      columns.push({
        Header: "Location",
        accessor: "tag",
        Cell: (data) => {
          try {
            const {
              value,
              row: { original },
            } = data;
            if (leaderboard) {
              const { businessName } = original;
              return (
                <>
                  <Chip label={businessName} size="small" sx={{ mb: 1 }} />
                  <Typography variant="body2">{value}</Typography>
                </>
              );
            }
            return value || "";
          } catch (err) {
            return "-";
          }
        },
      });
    }
    return columns;
  } catch (err) {
    return [];
  }
};

const financeColumns = [
  {
    Header: "Total Revenue",
    accessor: "totalRevenue",
    Cell: ({ value }) => `$${value}`,
  },
  {
    Header: "Total Expense",
    accessor: "totalExpense",
    Cell: ({ value }) => `$${value}`,
  },
  {
    Header: "Autopay Revenue",
    accessor: "totalAutopayRevenue",
    Cell: ({ value }) => `$${value}`,
  },
  {
    Header: "Retail Revenue",
    accessor: "totalRetailRevenue",
    Cell: ({ value }) => `$${value}`,
  },
  {
    Header: "Net Profit",
    accessor: "netProfitPercentage",
    Cell: ({ value }) => `${value}%`,
  },
  {
    Header: "Marketing",
    accessor: "marketingPercentage",
    Cell: ({ value }) => `${value}%`,
  },
  {
    Header: "Payroll",
    accessor: "payrollPercentage",
    Cell: ({ value }) => `${value}%`,
  },
  {
    Header: "Operating",
    accessor: "operatingPercentage",
    Cell: ({ value }) => `${value}%`,
  },
  {
    Header: "Equipment/Annual",
    accessor: "equipmentAnnualPercentage",
    Cell: ({ value }) => `${value}%`,
  },
];

const operationColumns = [
  {
    Header: "Active",
    accessor: "active",
  },
  {
    Header: "Leads",
    accessor: "leads",
  },
  {
    Header: "Prospects",
    accessor: "prospects",
  },
  {
    Header: "New",
    accessor: "new",
  },
  {
    Header: "Lost",
    accessor: "lost",
  },
  {
    Header: "Net (+/-)",
    accessor: "net",
  },
  {
    Header: "ACV",
    accessor: "acv",
    Cell: ({ value }) => `$ ${parseFloat(value)}`,
  },
  {
    Header: "Lead To Client %",
    accessor: "leadToClientPercentage",
    Cell: ({ value }) => `${parseFloat(value)}%`,
  },
  {
    Header: "Conversion %",
    accessor: "conversionPercentage",
    Cell: ({ value }) => `${parseFloat(value)}%`,
  },
  {
    Header: "Churn %",
    accessor: "churnPercentage",
    Cell: ({ value }) => `${parseFloat(value)}%`,
  },
  {
    Header: "Lead Sources",
    accessor: "leadSources",
    Cell: ({ value }) => {
      try {
        value = value.split(",");
        return value.map((x) => <Chip label={x} sx={{ mb: 0.5 }} />);
      } catch {
        return "";
      }
    },
  },
];

const leverageColumns = [
  {
    Header: "Total Hours",
    accessor: "totalHours",
    Cell: ({ value }) => value || "0",
  },
  {
    Header: "Sessions",
    accessor: "sessions",
    Cell: ({ value }) => value || "0",
  },
  {
    Header: "Owner Free Days",
    accessor: "ownerFreeDays",
    Cell: ({ value }) => value || "0",
  },
  {
    Header: "Utilization",
    accessor: "utilization",
    Cell: ({ value }) => `${parseFloat(value)}%`,
  },
  {
    Header: "$ Per Hour",
    Cell: () => "N/A",
  },
  {
    Header: "Autopay %",
    accessor: "autopayPercentage",
    Cell: ({ value }) => `${parseFloat(value)}%`,
  },
  {
    Header: "Retail Per Autopay",
    accessor: "retailPerAutopay",
    Cell: ({ value }) => `$ ${parseFloat(value)}`,
  },
  {
    Header: "CAC",
    accessor: "cac",
    Cell: ({ value }) => `$ ${parseFloat(value)}`,
  },
];

export const FinanceTable = ({
  data,
  loading,
  header,
  ranked,
  profileWise,
  monthly,
  leaderboard,
}) => {
  const columns = useMemo(() => [
    ...commonColumns({ ranked, monthly, profileWise, leaderboard }),
    ...financeColumns,
  ]);
  const tableManager = useTableManager(
    {
      sort: { field: ranked ? "rank" : "tag", order: "asc" },
    },
    { loading: false }
  );
  return (
    <NormalTable
      header={header}
      tableManager={tableManager}
      getRowId={(row) => row.id}
      columns={columns}
      loading={loading}
      data={data}
    />
  );
};

export const OperationTable = ({
  data,
  loading,
  header,
  ranked,
  monthly,
  profileWise,
  leaderboard,
}) => {
  const tableManager = useTableManager(
    {
      sort: { field: ranked ? "rank" : "tag", order: "asc" },
    },
    { loading: false }
  );
  const columns = useMemo(() => [
    ...commonColumns({ ranked, monthly, profileWise, leaderboard }),
    ...operationColumns,
  ]);
  return (
    <NormalTable
      header={header}
      tableManager={tableManager}
      getRowId={(row) => row.id}
      columns={columns}
      loading={loading}
      data={data}
    />
  );
};

export const LeverageTable = ({
  data,
  loading,
  header,
  ranked,
  monthly,
  profileWise,
  leaderboard,
}) => {
  const tableManager = useTableManager(
    {
      sort: { field: ranked ? "rank" : "tag", order: "asc" },
    },
    { loading: false }
  );
  const columns = useMemo(() => [
    ...commonColumns({ ranked, monthly, profileWise, leaderboard }),
    ...leverageColumns,
  ]);
  return (
    <NormalTable
      header={header}
      tableManager={tableManager}
      getRowId={(row) => row.id}
      columns={columns}
      loading={loading}
      data={data}
    />
  );
};

export const AllLocationData = ({
  header,
  loading,
  data,
  ranked,
  profileWise,
  monthly,
  leaderboard,
}) => {
  const tableManager = useTableManager(
    {
      sort: { field: ranked ? "rank" : "tag", order: "asc" },
    },
    { loading: false }
  );
  const columns = useMemo(() => [
    ...commonColumns({ ranked, monthly, profileWise, leaderboard }),
    ...operationColumns,
    ...financeColumns,
    ...leverageColumns,
  ]);

  return (
    <NormalTable
      header={header}
      tableManager={tableManager}
      getRowId={(row) => row.id}
      columns={columns}
      loading={loading}
      data={data}
    />
  );
};
