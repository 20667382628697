import React, { useEffect } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
  Paper,
  TablePagination,
  TableSortLabel,
  Typography,
  Box,
  useTheme,
} from "@mui/material";

export const NormalTable = ({
  tableManager,
  columns,
  data,
  header,
  formOpened,
  ...props
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      getRowId: props.getRowId,
      disableSortRemove: true,
      autoResetSortBy: false,
      initialState: {
        sortBy: [
          {
            id: tableManager.getSortField(),
            desc: tableManager.getSortOrder() === "desc" ? true : false,
          },
        ],
      },
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setPageSize(50);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const theme = useTheme();
  const darkMode = theme.palette.mode == "dark";
  return (
    <Paper variant="outlined" width={"inherit"}>
      {header ? header : null}
      <TableContainer sx={{ position: "relative" }}>
        {props.loading && (
          <LinearProgress sx={{ width: "100%", position: "absolute" }} />
        )}
        <Table {...getTableProps}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => {
                  const style = { width: column.width };
                  if (column.hide) return null;
                  if (index == 0) {
                    style.left = 0;
                    if (!formOpened) {
                      style.position = "sticky";
                      style.zIndex = "10000";
                    }
                    style.backgroundColor = darkMode ? "black" : "white";
                  }
                  return (
                    <TableCell
                      {...column.getHeaderProps({
                        ...column.getSortByToggleProps(),
                        style,
                      })}
                    >
                      {column.id === "actions" ? (
                        column.render("Header")
                      ) : (
                        <TableSortLabel
                          active={column.isSorted}
                          direction={column.isSortedDesc ? "desc" : "asc"}
                        >
                          {column.render("Header")}
                        </TableSortLabel>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell, index) => {
                    if (cell.column.hide) return null;
                    let style = {};
                    if (index == 0) {
                      style.left = 0;
                      if (!formOpened) {
                        style.position = "sticky";
                        style.zIndex = "10000";
                      }
                      style.backgroundColor = darkMode ? "black" : "white";
                    }
                    return (
                      <TableCell {...cell.getCellProps({ style })}>
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data ? data.length : 0}
        page={pageIndex}
        rowsPerPage={pageSize}
        onRowsPerPageChange={(e) => setPageSize(e.target.value)}
        onPageChange={(e, number) => gotoPage(number)}
      />
    </Paper>
  );
};

export const ServerTable = ({ columns, data, tableManager, ...props }) => {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable({
      columns,
      data,
      getRowId: props.getRowId,
    });
  const loading =
    props.loading !== undefined ? props.loading : tableManager.getLoading();
  return (
    <Paper variant="outlined">
      <TableContainer sx={{ position: "relative" }}>
        {loading && (
          <LinearProgress sx={{ width: "100%", position: "absolute" }} />
        )}
        <Table {...getTableProps}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  if (column.hide) return null;
                  return (
                    <CustomTableCell
                      key={column.id}
                      tableManager={tableManager}
                      loading={loading}
                      column={column}
                    />
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    if (cell.column.hide) return null;
                    return (
                      <TableCell {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={tableManager.get().rowCount}
          page={tableManager.get().page}
          rowsPerPage={tableManager.get().pageSize}
          onRowsPerPageChange={(e) => {
            !loading && tableManager.set({ pageSize: e.target.value, page: 0 });
          }}
          onPageChange={(e, number) =>
            !loading && tableManager.set({ page: number })
          }
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
        />
      </TableContainer>
    </Paper>
  );
};

const CustomTableCell = ({ tableManager, column, loading }) => {
  console.log(column.id);
  return (
    <TableCell {...column.getHeaderProps({ style: { width: column.width } })}>
      {column.id === "actions" ? (
        column.render("Header")
      ) : (
        <TableSortLabel
          active={tableManager.getSortField() === column.id}
          direction={tableManager.getSortOrder()}
          onClick={() => {
            if (loading) return;
            const currentField = tableManager.getSortField();
            if (currentField !== column.id) {
              const currentOrder = tableManager.getSortOrder();
              let orderToSet = "desc";
              if (currentOrder) {
                orderToSet = currentOrder;
              }
              tableManager.set({
                sort: { field: column.id, order: orderToSet },
              });
            } else {
              const currentOrder = tableManager.getSortOrder();
              tableManager.set({
                sort: {
                  field: column.id,
                  order: currentOrder === "asc" ? "desc" : "asc",
                },
              });
            }
          }}
        >
          {column.render("Header")}
        </TableSortLabel>
      )}
    </TableCell>
  );
};
