import * as yup from "yup";

export const UserBaseSchema = yup.object({
  email: yup
    .string()
    .typeError("Email is invalid")
    .email("Email has an invalid format")
    .trim()
    .required("The email is required"),
  businessName: yup.string("Business name is invalid").trim(),
  yearOfEstablishment: yup
    .number("Year of establishment must be a valid year")
    .min(1000, "Invalid year")
    .max(new Date().getFullYear(), "Invalid year")
    .transform((_, val) => (Boolean(parseInt(val)) ? parseInt(val) : null))
    .nullable(true),
  userName: yup.string("User name is invalid").trim(),
  userDesignation: yup.string("User designation is invalid").trim(),
  UserTypeId: yup
    .number()
    .typeError("Invalid User Type")
    .min(0, "Invalid User Type")
    .required("User Type is required"),
  password: yup.string().min(10, "Password must be of at least 10 characters"),
});
