import { apiCaller } from "../utils/generalUtils";
import {
  USER_ADD_DATA,
  USER_ADD_TO_DATA,
  USER_REMOVE_FROM_DATA,
  USER_SET_LOADING,
  USER_UPDATE_DATA,
} from "./types";
import {
  appStartLoading,
  appStopLoading,
  showSuccessMessage,
  showErrorMessageFromAPI,
} from "../actions";

export const userFetch = (options) => {
  const force = options ? options.force : false;
  return (dispatch, getState) => {
    const { data } = getState().users;
    if (data.length > 0 && force === false) {
      return;
    }
    try {
      dispatch(userLoad(true));
      apiCaller
        .get("/users")
        .then(({ data }) => {
          dispatch({
            type: USER_ADD_DATA,
            payload: data.data,
          });
        })
        .catch((err) => {
          dispatch(showErrorMessageFromAPI({ err }));
        })
        .finally(() => {
          dispatch(userLoad(false));
        });
    } catch (err) {
      dispatch(userLoad(false));
      dispatch(showErrorMessageFromAPI({ err }));
    }
  };
};

export const userDelete = ({ id, onSuccess }) => {
  return (dispatch) => {
    dispatch(appStartLoading());
    apiCaller
      .delete(`/users/${id}`)
      .then(({ data }) => {
        dispatch({
          type: USER_REMOVE_FROM_DATA,
          payload: id,
        });
        dispatch(showSuccessMessage("User deleted"));
        onSuccess && onSuccess();
      })
      .catch((err) => {
        dispatch(showErrorMessageFromAPI({ err }));
      })
      .finally(() => {
        dispatch(appStopLoading());
      });
  };
};

export const userActivityToggle = ({ id, onSuccess }) => {
  return (dispatch) => {
    dispatch(appStartLoading());
    apiCaller
      .post(`/users/toggleActivity/${id}`)
      .then(({ data: { data } }) => {
        dispatch({
          type: USER_UPDATE_DATA,
          payload: data,
        });
        dispatch(showSuccessMessage("User activity toggled"));
        onSuccess && onSuccess();
      })
      .catch((err) => {
        dispatch(showErrorMessageFromAPI({ err }));
      })
      .finally(() => {
        dispatch(appStopLoading());
      });
  };
};

export const userUpdate = ({ data, onSuccess, onFinally }) => {
  return (dispatch) => {
    dispatch(appStartLoading());
    apiCaller
      .put("/users", data)
      .then(({ data: { data } }) => {
        dispatch({
          type: USER_UPDATE_DATA,
          payload: data,
        });
        dispatch(showSuccessMessage("User updated"));
        onSuccess && onSuccess();
      })
      .catch((err) => {
        dispatch(showErrorMessageFromAPI({ err }));
      })
      .finally(() => {
        dispatch(appStopLoading());
      });
  };
};

export const userCreate = ({ data, onSuccess, onFinally }) => {
  return (dispatch, getState) => {
    dispatch(appStartLoading());
    apiCaller
      .post("/users", data)
      .then(({ data: { data } }) => {
        const r = getState().users;
        if (r.data.length > 0) {
          dispatch({
            type: USER_ADD_TO_DATA,
            payload: data,
          });
        } else {
          dispatch(userFetch());
        }
        dispatch(showSuccessMessage("User created"));
        onSuccess && onSuccess();
      })
      .catch((err) => {
        dispatch(showErrorMessageFromAPI({ err }));
      })
      .finally(() => {
        dispatch(appStopLoading());
      });
  };
};

export const userLoad = (loading) => {
  return {
    type: USER_SET_LOADING,
    payload: loading,
  };
};
