import {
  Stack,
  Paper,
  Typography,
  Divider,
  Box,
  CircularProgress,
  Alert,
  Grid,
} from "@mui/material";
import StarImage from "../images/star.svg";

const InfoWrapper = (props) => {
  return (
    <Paper
      variant="outlined"
      sx={{
        height: "100px",
        minWidth: "200px",
        p: 1,
        ...props.sx,
      }}
    >
      {props.children}
    </Paper>
  );
};

const NormalBox = (props) => {
  return (
    <Stack spacing={0.5}>
      <Typography variant="button">{props.title}</Typography>
      <Divider />
      <Stack direction={"row"}>
        <Box sx={{ flex: 1 }}>
          <Typography sx={{ color: "text.secondary" }} variant="caption">
            Result
          </Typography>
          <Typography variant="body1">{props.result}</Typography>
        </Box>
        <Box sx={{ flex: 1 }}>
          <Typography sx={{ color: "text.secondary" }} variant="caption">
            {props.effectivenessLabel}
          </Typography>
          <Typography variant="body1">{props.effectiveness}%</Typography>
        </Box>
      </Stack>
    </Stack>
  );
};

const LeaderBox = ({ data, profileWise }) => {
  const multiple = data.length > 1;
  return (
    <Stack spacing={1}>
      {data.map((d) => (
        <Paper sx={{ p: 1 }} variant="outlined">
          <Stack spacing={0.5}>
            {multiple && d.salesMethod && (
              <>
                <Box>
                  <Typography
                    variant="caption"
                    sx={{ color: "text.secondary" }}
                  >
                    Sales Method:
                  </Typography>
                  <Typography variant="body1">{d.salesMethod}</Typography>
                </Box>
                <Divider />
              </>
            )}
            {d.tag && !profileWise && (
              <>
                <Box>
                  <Typography
                    variant="caption"
                    sx={{ color: "text.secondary" }}
                  >
                    Location name:
                  </Typography>
                  <Typography variant="body1">{d.tag}</Typography>
                </Box>
                <Divider />
              </>
            )}
            <Box>
              <Typography variant="caption" sx={{ color: "text.secondary" }}>
                Business name:
              </Typography>
              <Typography variant="body1">{d.businessName}</Typography>
            </Box>
            <NormalBox {...d} />
          </Stack>
        </Paper>
      ))}
    </Stack>
  );
  return multiple ? "many" : "one";
};

const LeaderTitle = ({ title }) => {
  return (
    <Box sx={{ pb: 1 }}>
      <img src={StarImage} style={{ height: "16px", paddingRight: "6px" }} />
      <Typography as="span" variant="h6" sx={{ textTransform: "uppercase" }}>
        {title} Leader
      </Typography>
    </Box>
  );
};

const InfoGrid = ({ data, leaderboard, profileWise }) => {
  try {
    if (data == undefined || !data.info) return null;
    if (data === null) {
      return (
        <InfoWrapper
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="button">Loading Info</Typography>
          <Box>
            <CircularProgress size={25} />
          </Box>
        </InfoWrapper>
      );
    }
    if (data === false) {
      return (
        <InfoWrapper
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Alert severity="error">Error fetching Location Data Info</Alert>
        </InfoWrapper>
      );
    }
    if (leaderboard) {
      const level1Data = data.info.filter((d) => d.level == 1);
      const level2Data = data.info.filter((d) => d.level == 2);
      return (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
          }}
        >
          <Grid container spacing={2}>
            {level1Data.map(({ title, data }) => {
              return (
                <Grid item xs={4}>
                  <InfoWrapper sx={{ height: "auto" }}>
                    <LeaderTitle title={title} />
                    <LeaderBox data={data} profileWise={profileWise} />
                  </InfoWrapper>
                </Grid>
              );
            })}
            {level2Data.map(({ title, data }) => {
              return (
                <Grid item xs={6}>
                  <InfoWrapper sx={{ height: "auto" }}>
                    <LeaderTitle title={title} />
                    <LeaderBox data={data} />
                  </InfoWrapper>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      );
    } else {
      return (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
          {data.info.map((data) => (
            <InfoWrapper sx={{ flexGrow: 1, flexBasis: 0 }}>
              <NormalBox {...data} />
            </InfoWrapper>
          ))}
        </Box>
      );
    }
  } catch (err) {
    return (
      <Alert severity="error">
        Error fetching Location Data Grid Info, please submit a report on the
        Feedback page
      </Alert>
    );
  }
};

export { InfoGrid };
