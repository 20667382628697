import { Alert } from "@mui/material";
import React from "react";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  componentDidCatch(error, info) {
    this.setState({ hasError: true });
  }
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  render() {
    if (this.state.hasError) {
      return (
        <Alert severity="error">
          A problem occurred displaying an element, please let the webmaster
          know through the feedback page
        </Alert>
      );
    }

    return this.props.children;
  }
}
