export const APP_SET_LOGIN = "001";
export const APP_HIDE_NOTIFICATION = "002";
export const APP_SHOW_NOTIFICATION = "003";
export const APP_START_LOADING = "004";
export const APP_STOP_LOADING = "005";
export const APP_SWITCH_DARK_MODE = "006";
export const APP_TOGGLE_SIDEMENU = "007";

export const LOCATION_ADD_DATA = "101";
export const LOCATION_SET_LOADING = "102";
export const LOCATION_REMOVE_FROM_DATA = "103";
export const LOCATION_ADD_TO_DATA = "104";
export const LOCATION_ARCHIVE_TOGGLE = "105";
export const LOCATION_UPDATE_DATA = "106";

export const LOCATION_DATA_ADD_PERIOD_DATA = "200";

export const PROFILE_SET_DATA = "300";
export const PROFILE_HAS_COMPLETED_SETUP = "301";

export const USER_ADD_DATA = "400";
export const USER_REMOVE_FROM_DATA = "401";
export const USER_SET_LOADING = "402";
export const USER_ADD_TO_DATA = "403";
export const USER_UPDATE_DATA = "404";

export const USERTYPE_SET_LOADING = "500";
export const USERTYPE_ADD_DATA = "501";

export const HOME_SET_DATA = "600";
