import {
  LOCATION_ADD_DATA,
  LOCATION_REMOVE_FROM_DATA,
  LOCATION_SET_LOADING,
  LOCATION_ADD_TO_DATA,
  LOCATION_UPDATE_DATA,
  LOCATION_ARCHIVE_TOGGLE,
} from "../actions/types";

const initialState = {
  data: [],
  loading: false,
  loaded: false,
};

const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOCATION_SET_LOADING: {
      return { ...state, loading: action.payload };
    }
    case LOCATION_ADD_DATA: {
      return { ...state, data: action.payload, loaded: true };
    }
    case LOCATION_ADD_TO_DATA:
      return {
        ...state,
        data: [...state.data, action.payload],
      };
    case LOCATION_REMOVE_FROM_DATA: {
      return {
        ...state,
        data: state.data.filter(({ id }) => id !== action.payload),
      };
    }
    case LOCATION_UPDATE_DATA: {
      let dataForUpdate = [...state.data];
      let indexForUpdate = dataForUpdate.findIndex(
        (l) => l.id == action.payload.id
      );
      dataForUpdate.splice(indexForUpdate, 1, action.payload);
      return { ...state, data: dataForUpdate };
    }
    case LOCATION_ARCHIVE_TOGGLE: {
      const dataArchiveUpdate = (() => {
        const data = [...state.data];
        const dataToUpdate = data.find((r) => r.id == action.payload);
        dataToUpdate.archived = !dataToUpdate.archived;
        return data;
      })();
      return {
        ...state,
        data: [...dataArchiveUpdate],
      };
    }
    default:
      return state;
  }
};

export { locationReducer };
