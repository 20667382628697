import { apiCaller } from "../utils/generalUtils";
import { USERTYPE_SET_LOADING, USERTYPE_ADD_DATA } from "./types";
import { showErrorMessageFromAPI } from "../actions";

export const userTypeFetch = (options) => {
  const force = options ? options.force : false;
  return (dispatch, getState) => {
    const { data } = getState().userTypes;
    if (data.length > 0 && force === false) {
      return;
    }
    try {
      dispatch(userTypeLoad(true));
      apiCaller
        .get("/userTypes")
        .then(({ data }) => {
          dispatch({
            type: USERTYPE_ADD_DATA,
            payload: data.data,
          });
        })
        .catch((err) => {
          dispatch(showErrorMessageFromAPI({ err }));
        })
        .finally(() => {
          dispatch(userTypeLoad(false));
        });
    } catch (err) {
      dispatch(userTypeLoad(false));
      dispatch(showErrorMessageFromAPI({ err }));
    }
  };
};

export const userTypeLoad = (loading) => {
  return {
    type: USERTYPE_SET_LOADING,
    payload: loading,
  };
};
