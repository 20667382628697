import moment from "moment"

const currentTimezone = global.profitMadeSimpleTimezone

export const currentYear = () => {
  const year = moment().format("YYYY")
  return parseInt(year)
}

export const currentWeek = year => {
  var week = moment().week()
  return parseInt(week)
}

export const currentMonth = () => {
  var month = moment().month() + 1
  return parseInt(month)
}

export const weekOptions = year => {
  const startingWeek = 1
  let endingWeek = moment(year, "YYYY").isoWeeksInYear()
  if (currentYear() == year) {
    endingWeek = currentWeek(year)
  }
  let options = Array.apply(null, { length: endingWeek + 1 })
    .map(Number.call, Number)
    .slice(startingWeek)
  options = options.reverse()
  options = options.map(week => ({
    id: week,
    label: `Week ${week} [${getWeekRange(week, year)}]`,
  }))
  return options
}
export const quarterOptions = year => {
  const startQuarter = 1
  let endQuarter = 4
  if (year == currentYear()) {
    endQuarter = moment(year, "YYYY").quarter()
  }
  let options = Array.apply(null, { length: endQuarter + 1 })
    .map(Number.call, Number)
    .slice(startQuarter)
  options = options.reverse()
  options = options.map(quarter => ({
    id: quarter,
    label: `Q${quarter} [${getQuarterRange(quarter, year)}]`,
  }))
  return options
}
export const monthOptions = year => {
  const startingMonth = 1
  let endingMonth = 12
  if (currentYear() == year) {
    endingMonth = currentMonth(year)
  }
  let options = Array.apply(null, { length: endingMonth + 1 })
    .map(Number.call, Number)
    .slice(startingMonth)
  options = options.reverse()
  options = options.map(month => ({
    id: month,
    label: moment(month, "M").format("MMMM"),
  }))
  return options
}

export const getQuarterRange = (quarter, year) => {
  const start = moment(year, "YYYY").quarter(quarter).startOf("quarter")
  const end = moment(year, "YYYY").quarter(quarter).endOf("quarter")
  return `${moment(start).format("DD MMM")} - ${moment(end).format("DD MMM")}`
}

export const getWeekRange = (week, year) => {
  const start = moment(year, "YYYY").week(week).startOf("week")
  const end = moment(year, "YYYY").week(week).endOf("week")
  return `${moment(start).format("DD MMM")} - ${moment(end).format("DD MMM")}`
}

export const currentDate = () => {}
