import { connect } from "react-redux";
import { useEffect } from "react";
import {
  Box,
  Modal,
  Typography,
  Grid,
  IconButton,
  Backdrop,
  CircularProgress,
  Collapse,
} from "@mui/material";

import { Close as CloseIcon } from "@mui/icons-material";

let FormModal = ({ children, open, onClose, width, header, loading }) => {
  useEffect(() => {
    return () => {
      //for cleanup
    };
  });
  return (
    <Modal
      closeAfterTransition
      disableScrollLock={true}
      open={open}
      BackdropProps={{
        timeout: 500,
      }}
      sx={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        overflowY: "scroll",
      }}
    >
      <Collapse in={open}>
        <Box
          sx={{
            mt: 10,
            mb: 4,
            width: width || 500,
            minHeight: "0px",
            bgcolor: "background.paper",
            boxShadow: 12,
            p: 4,
          }}
        >
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          {Boolean(header) && (
            <Grid container>
              <Grid item sx={{ flex: 1 }}>
                <Typography variant="h5" sx={{ mb: 3 }}>
                  {header}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton sx={{ padding: 0 }} onClick={onClose}>
                  <CloseIcon fontSize="large" />
                </IconButton>
              </Grid>
            </Grid>
          )}
          {children}
        </Box>
      </Collapse>
    </Modal>
  );
};

const mstp = ({ app }) => {
  return {
    loading: app.loading,
  };
};

FormModal = connect(mstp, {})(FormModal);

export { FormModal };
