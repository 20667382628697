import { Box } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { loadLocationData } from "../../actions";
import {
  DataFilter,
  DataHolder,
  ErrorBoundary,
  Header,
  InfoGrid,
} from "../../components";

const LeaderboardPage = () => {
  const dispatch = useDispatch();
  const [tableMode, setTableMode] = useState(true);
  const [filter, setFilter] = useState({
    period: "month",
  });
  const [data, setData] = useState([]);
  console.log(filter);
  const downloadData = (downloadOption) => {
    dispatch(
      loadLocationData({
        filter: { ...filter, download: downloadOption },
        leaderboard: true,
        onSuccess: (data) => {},
        onError: () => {},
      })
    );
  };
  const loadData = (filter) => {
    setData(null);
    setTableMode(true);
    dispatch(
      loadLocationData({
        leaderboard: true,
        filter,
        onSuccess: (data) => {
          setData(data);
        },
        onError: () => setData(false),
      })
    );
  };
  return (
    <ErrorBoundary>
      <Box>
        <Header heading={"Leaderboard"} />
        <DataFilter
          filter={filter}
          setFilter={setFilter}
          loadData={loadData}
          tableMode={tableMode}
          toggleTableMode={() => setTableMode(!tableMode)}
          leaderboard={true}
        />
        {/* <InfoGrid
          data={data}
          leaderboard={true}
          profileWise={filter.profileWise}
        /> */}
        <DataHolder
          leaderboard
          ranked
          filter={filter}
          profileWise={filter.profileWise}
          downloadData={downloadData}
          tableMode={tableMode}
          data={data}
        />
      </Box>
    </ErrorBoundary>
  );
};

export default LeaderboardPage;
