import { combineReducers } from "redux";

import { appReducer } from "./appReducer";
import { locationReducer } from "./locationReducer";
import { profileReducer } from "./profileReducer";
import { userReducer } from "./userReducer";
import { userTypeReducer } from "./userTypeReducer";
import { homeReducer } from "./homeReducer";

export default combineReducers({
  app: appReducer,
  profile: profileReducer,
  locations: locationReducer,
  users: userReducer,
  userTypes: userTypeReducer,
  home: homeReducer,
});
